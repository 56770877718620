import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const updateIncludedInstitutionMerchandise = async (id, data) => {
	try {
		const res = await axiosInstanceForTemplate.patch(
			`merchandise/includedInstitution/${id}`,
			data
		);

		return res;
	} catch (error) {
		return error;
	}
};

export default { updateIncludedInstitutionMerchandise };

import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Routes from './Routes';
import LoginPage from './pages/Login/LoginPage';

const hist = createBrowserHistory();
const App = (props) => {
	return (
		<Router history={hist}>
			<Switch>
				<Route
					exact
					path='/login'
					render={(routeProps) => <LoginPage {...routeProps} {...props} />}
				/>
				<Route
					path='/'
					component={(routeProps) => <Routes {...routeProps} {...props} />}
				/>
			</Switch>
		</Router>
	);
};

export default App;

import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const allMerchandises = async (params) => {
	try {
		const res = await axiosInstanceForTemplate.get(`/merchandise/search`, {
			params,
			data: null
		});
		return res;
	} catch (error) {
		return error.response.data;
	}
};

import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const createMerchandise = async (data) => {
	const formData = new FormData();
	formData.append('name', data.name);
	formData.append('source', data.source);
	formData.append('price', data.price);
	formData.append('colors', JSON.stringify(data.colors));
	formData.append('image', data.image);

	try {
		const res = await axiosInstanceForTemplate.post(`/merchandise`, formData);
		return res;
	} catch (error) {
		return error;
	}
};

export default { createMerchandise };

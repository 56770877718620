import axiosInstanceForActivityTracker from "./axiosInstanceForActivityTracker";

export const allInstitution = async () => {
  try {
    const res = await axiosInstanceForActivityTracker.get(
      `activity-track/institutes`,
      {
        data: null,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

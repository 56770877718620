import axios from 'axios';
import { activityTrackerHost, activityTrackerToken } from '../config/env';

const headers = {
	'Content-Type': 'application/json',
	Authorization: activityTrackerToken
};

const axiosInstanceForActivityTracker = axios.create({
	baseURL: activityTrackerHost,
	headers
});

export default axiosInstanceForActivityTracker;

import axiosInstanceForTemplate from "./axiosInstanceForTemplate";

export const addCategory = async (categoryInput) => {
  const { categoryName, source } = categoryInput;

  const data = {
    categoryName: categoryName,
    source: source,
  };

  try {
    const res = await axiosInstanceForTemplate.post("/category", data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};

import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  ListItemText,
  Input,
  Select,
  CircularProgress,
  Card,
  CardContent,
} from "@material-ui/core";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { AddAlert, Grain } from "@material-ui/icons";
import { createProductCatalog } from "../../../../dataService/createProductCatalog";
import { editProductCatalog } from "../../../../dataService/editProductCatalog";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "./ProductCatalogFormComponent.styles";
import { useState } from "react";
import Radio from "@material-ui/core/Radio";
import DeleteIcon from "@material-ui/icons/Delete";
import { Add } from "@material-ui/icons";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TieredTest } from "../Commission/TieredTest";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import { joiResolver } from "@hookform/resolvers/joi";
import {
  commissionTypes,
  productCatalogSchema,
  resourceTypes,
} from "./ProductCatalogFormComponent.utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProductCatalogFormComponent = ({
  isLoading,
  productCatalogInfo,
  isEditPage,
  setIsLoading,
  id,
  setSnackbarConfig,
  institutionList,
}) => {
  const { push } = useHistory();
  const classes = useStyles();

  const [addRow, setAddRow] = useState([{ from: "", to: "", value: "" }]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addRow];
    list[index][name] = value;
    setAddRow(list);
  };

  const handleAddClick = () => {
    setAddRow([...addRow, { from: "", to: "", value: "" }]);
  };

  const currencyOptions = [
    { id: "USD", name: "USD" },
    { id: "AUD", name: "AUD" },
    { id: "GBP", name: "GBP" },
  ];

  const clientTypes = [
    { id: "Herff Jones College", name: "Herff Jones College" },
    { id: "Herff Jones High School", name: "Herff Jones High School" },
    { id: "Herff Jones School District", name: "Herff Jones School District" },
    { id: "Institution Group", name: "Institution Group" },
    { id: "Single Institute", name: "Single Institute" },
  ];

  const defaultValues = {
    // pricing: "",
    currency: "",
    resourceType: "",
    recalculationType: "historically",
    isCutOffDate: false,
    isTaxDeducted: false,
    isTransactionFee: false,
    isPartnerRevShare: false,
    clipCommision: [],
    instituteIds: [],
    //clientType: "",
    //flatTypeValue: "",
    // cutOffDate: null,
    // taxDeductedValue: null,
    // transactionFeeValue: null,

    // commissionType: null,
    clipCommissionTypes: {},
  };

  useEffect(() => {
    if (!_.isEmpty(productCatalogInfo)) {
      setValue("resourceType", productCatalogInfo.resourceType);
      setValue("clientType", productCatalogInfo.clientType);
      setValue("instituteIds", JSON.parse(productCatalogInfo.institutionName));
      // setValue("pricing", productCatalogInfo.pricing);
      setValue("currency", productCatalogInfo.currency);
      setValue("recalculationType", productCatalogInfo.recalculationType);

      if (!!productCatalogInfo.cutOffDate) {
        setValue("isCutOffDate", true);
        setValue(
          "cutOffDate",
          moment(productCatalogInfo.cutOffDate).utc().format("YYYY-MM-DD")
        );
      }

      if (!!productCatalogInfo.taxDeductedValueType) {
        setValue("isTaxDeducted", true);
        setValue(
          "taxDeductedValueType",
          productCatalogInfo.taxDeductedValueType
        );
      }
      if (!!productCatalogInfo.taxDeductedValue) {
        setValue("isTaxDeducted", true);
        setValue("taxDeductedValue", productCatalogInfo.taxDeductedValue);
      }

      if (!!productCatalogInfo.transactionFeeValueType) {
        setValue("isTransactionFee", true);
        setValue(
          "transactionFeeValueType",
          productCatalogInfo.transactionFeeValueType
        );
      }
      if (!!productCatalogInfo.transactionFeeValue) {
        setValue("isTransactionFee", true);
        setValue("transactionFeeValue", productCatalogInfo.transactionFeeValue);
      }

      setValue("commissionType", productCatalogInfo.partnerRevShareValueType);

      if (!!productCatalogInfo.commissionStructureValue) {
        setValue("isPartnerRevShare", true);
        if (productCatalogInfo.partnerRevShareValueType === "flatAmount") {
          setValue(
            "flatTypeValueType",
            productCatalogInfo.commissionStructureValue.type
          );
          setValue(
            "flatTypeValue",
            productCatalogInfo.commissionStructureValue.value
          );
        }

        if (productCatalogInfo.partnerRevShareValueType === "tiered") {
          setValue(
            "clipCommision",
            productCatalogInfo.commissionStructureValue.slice(1)
          );
          setValue(
            "clipCommissionTypes",
            productCatalogInfo.commissionStructureValue[0]
          );
        }
      }
    }
  }, [productCatalogInfo]);

  const resourcesOptions = [
    {
      value: resourceTypes.clientType,
      label: "CLIENT TYPE",
    },
    {
      value: resourceTypes.institutionName,
      label: "INSTITUTION NAME",
    },
  ];

  const commissionOptions = [
    {
      value: commissionTypes.flatType,
      label: "FLAT",
      id: "Flat",
      name: "CLIENT TYPE",
    },
    {
      value: commissionTypes.tieredType,
      label: "TIERED",
      id: "Tiered",
      name: "TIERED",
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState,
    getValues,
    watch,
  } = useForm({
    shouldUnregister: false,
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: joiResolver(productCatalogSchema),
  });
  const { errors, dirtyFields } = formState;

  let {
    resourceType,
    commissionType,
    recalculationType,
    isCutOffDate,
    isTaxDeducted,
    isTransactionFee,
    isPartnerRevShare,
    instituteIds,
    clipCommision,
    clipCommissionTypes,
  } = watch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "clipCommision",
  });

  const handleFormSubmit = async (values) => {
    isEditPage ? handleFormEditSubmit(values) : handleFormAddSubmit(values);
  };

  const getCommissionStructure = async (values) => {
    let commissionStructure = {};

    if (values.commissionType === "flatAmount") {
      commissionStructure = {
        type: values.flatTypeValueType,
        value: values.flatTypeValue,
      };
    }
    if (values.commissionType === "tiered") {
      values.clipCommision.unshift(clipCommissionTypes);
      commissionStructure = values.clipCommision;
    }

    return commissionStructure;
  };

  const handleFormEditSubmit = async (values) => {
    setIsLoading(true);
    const obj = {
      resourceType: values.resourceType,
      clientType: values.clientType,
      institutionName: values.instituteIds ? values.instituteIds : [],
      // pricing: values.pricing,
      currency: values.currency,
      recalculationType: values.recalculationType,
      cutOffDate: values.cutOffDate,
      taxDeductedValueType: values.taxDeductedValueType,
      taxDeductedValue: values.taxDeductedValue,
      transactionFeeValueType: values.transactionFeeValueType,
      transactionFeeValue: values.transactionFeeValue,
      partnerRevShareValueType: values.commissionType,
      commissionStructureValue: await getCommissionStructure(values),
    };

    const res = await editProductCatalog(id, obj);
    if (res.status === 200) {
      setSnackbarConfig({
        open: true,
        message: "Product Catalog edited successfully!",
        icon: AddAlert,
        color: "success",
      });
      setTimeout(() => {
        push("/productCatalog");
      }, 2000);
    } else {
      setSnackbarConfig({
        open: true,
        message: "Error in update",
        icon: AddAlert,
        color: "error",
      });
    }
  };

  const handleFormAddSubmit = async (values) => {
    setIsLoading(true);
    const obj = {
      resourceType: values.resourceType,
      clientType: values.clientType,
      institutionName: values.instituteIds,
      // pricing: values.pricing,
      currency: values.currency,
      recalculationType: values.recalculationType,
      // recalculationValue: values.recalculationValue,
      cutOffDate: values.cutOffDate,
      taxDeductedValueType: values.taxDeductedValueType,
      taxDeductedValue: values.taxDeductedValue,
      transactionFeeValueType: values.transactionFeeValueType,
      transactionFeeValue: values.transactionFeeValue,
      partnerRevShareValueType: values.commissionType,
      commissionStructureValue: await getCommissionStructure(values),
    };

    const res = await createProductCatalog(obj);
    if (res.status === 200) {
      setSnackbarConfig({
        open: true,
        message: "Product Catalog creation successful!",
        icon: AddAlert,
        color: "success",
      });
      setTimeout(() => {
        push("/productCatalog");
      }, 2000);
    } else {
      setSnackbarConfig({
        open: true,
        message: " Creation Unsuccessful!",
        icon: AddAlert,
        color: "error",
      });
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.hederContainer}>
        <Typography variant="h4" className={classes.hederText}>
          {isEditPage ? "Edit Product Catalog" : "Create Product Catalog"}
        </Typography>
      </Card>

      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} title="Loading...." />
        </div>
      ) : (
        <div>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid xs={12}>
              <Grid container spacing={4}>
                <Grid style={{ marginTop: "20px" }} item xs={4}>
                  <Typography className={classes.inputFieldsTitle}>
                    Type
                  </Typography>
                  <Controller
                    control={control}
                    name="resourceType"
                    id="resourceType"
                    render={({ onChange, value }) => {
                      return (
                        <TextField
                          className={classes.selectionField}
                          select={!_.isEmpty(resourcesOptions)}
                          id="resourceType"
                          variant="outlined"
                          label={value ? "" : "Select Type"}
                          fullWidth
                          MenuProps={MenuProps}
                          disabled={!_.isEmpty(resourcesOptions)}
                          SelectProps={{
                            value: value,
                            renderValue: (selected) => {
                              const selectedResourceOptions =
                                selected &&
                                resourcesOptions.filter(
                                  (resourceType) =>
                                    resourceType.value === selected
                                )[0];

                              return (
                                selectedResourceOptions &&
                                selectedResourceOptions.label
                              );
                            },
                            onChange: onChange,
                            disabled: _.isEmpty(resourcesOptions),
                          }}
                          error={!!errors.resourceType}
                          helperText={errors?.resourceType?.message}
                        >
                          {!_.isEmpty(resourcesOptions) &&
                            resourcesOptions.map((resourceType) => (
                              <MenuItem
                                key={resourceType.value}
                                value={resourceType.value}
                              >
                                <ListItemText primary={resourceType.label} />
                              </MenuItem>
                            ))}
                        </TextField>
                      );
                    }}
                  />
                  <Grid item xs={12}>
                    {resourceType === resourceTypes.institutionName &&
                      !!institutionList.length && (
                        <div>
                          <Card className={classes.root}>
                            <CardContent>
                              <Controller
                                control={control}
                                name="instituteIds"
                                id="instituteIds"
                                render={({ onChange, value }) => {
                                  return (
                                    <Autocomplete
                                      id="instituteIds"
                                      value={value}
                                      control={control}
                                      multiple
                                      onChange={(_, event) =>
                                        onChange([...event])
                                      }
                                      autoHighlight
                                      options={institutionList}
                                      renderOption={(option) => (
                                        <MenuItem
                                          key={option.id}
                                          value={option.id}
                                        >
                                          <ListItemText primary={option.name} />
                                        </MenuItem>
                                      )}
                                      getOptionLabel={(option) => option.name}
                                      filterSelectedOptions
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            {...params}
                                            size="small"
                                            variant="outlined"
                                            placeholder="Institution Name"
                                          />
                                        );
                                      }}
                                      component="div"
                                    />
                                  );
                                }}
                              />
                            </CardContent>
                          </Card>
                        </div>
                      )}

                    {resourceType === resourceTypes.clientType && (
                      <Card className={classes.root}>
                        <CardContent>
                          <Controller
                            control={control}
                            name="clientType"
                            id="clientType"
                            render={({ onChange, value }) => {
                              return (
                                <TextField
                                  size="small"
                                  className={classes.selectionField}
                                  select={!_.isEmpty(clientTypes)}
                                  id="clientType"
                                  variant="outlined"
                                  label={value ? "" : "Client Type"}
                                  fullWidth
                                  value={value}
                                  MenuProps={MenuProps}
                                  disabled={!_.isEmpty(clientTypes)}
                                  SelectProps={{
                                    value: value,
                                    renderValue: (selected) => {
                                      const selectedClient =
                                        selected &&
                                        !_.isEmpty(clientTypes) &&
                                        clientTypes.filter(
                                          (clientTypes) =>
                                            clientTypes.id === selected
                                        )[0];
                                      return (
                                        selectedClient && selectedClient.name
                                      );
                                    },
                                    onChange: onChange,
                                    disabled: _.isEmpty(clientTypes),
                                  }}
                                  error={!!errors.clientType}
                                  helperText={errors?.clientType?.message}
                                >
                                  {!_.isEmpty(clientTypes) &&
                                    clientTypes.map((clientType) => (
                                      <MenuItem
                                        key={clientType.id}
                                        value={clientType.id}
                                      >
                                        <ListItemText
                                          primary={clientType.name}
                                        />
                                      </MenuItem>
                                    ))}
                                </TextField>
                              );
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                </Grid>
                {/* <Grid style={{ marginTop: "20px" }} item xs={4}>
                  <Typography className={classes.inputFieldsTitle}>
                    Pricing
                  </Typography>

                  <Controller
                    label="Pricing"
                    id="pricing"
                    name="pricing"
                    control={control}
                    variant="outlined"
                    fullWidth
                    render={({ onChange, value }, {}) => {
                      return (
                        <TextField
                          id="pricing"
                          type="number"
                          variant="outlined"
                          placeholder="Pricing"
                          MenuProps={MenuProps}
                          value={value}
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                          onChange={onChange}
                          fullWidth
                          error={!!errors.pricing}
                          helperText={errors?.pricing?.message}
                        />
                      );
                    }}
                  />
                </Grid> */}
                <Grid style={{ marginTop: "20px" }} item xs={4}>
                  <Typography className={classes.inputFieldsTitle}>
                    Currency
                  </Typography>
                  <Controller
                    control={control}
                    name="currency"
                    id="currency"
                    render={({ onChange, value }) => {
                      return (
                        <TextField
                          className={classes.selectionField}
                          select={!_.isEmpty(currencyOptions)}
                          id="currency"
                          variant="outlined"
                          label={value ? "" : "Currency"}
                          fullWidth
                          value={value}
                          MenuProps={MenuProps}
                          disabled={!_.isEmpty(currencyOptions)}
                          SelectProps={{
                            value: value,
                            renderValue: (selected) => {
                              const selectedCurrency =
                                selected &&
                                !_.isEmpty(currencyOptions) &&
                                currencyOptions.filter(
                                  (currency) => currency.id === selected
                                )[0];
                              return selectedCurrency && selectedCurrency.name;
                            },
                            onChange: onChange,
                            disabled: _.isEmpty(currencyOptions),
                          }}
                          error={!!errors.currency}
                          helperText={errors?.currency?.message}
                        >
                          {!_.isEmpty(currencyOptions) &&
                            currencyOptions.map((currency) => (
                              <MenuItem key={currency.id} value={currency.id}>
                                <ListItemText primary={currency.name} />
                              </MenuItem>
                            ))}
                        </TextField>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <div>
              <Grid container spacing={2}>
                <Grid style={{ marginTop: "10px" }} item xs={2}>
                  <Typography style={{ fontSize: 14, marginLeft: "10px" }}>
                    Update On Recalculation
                  </Typography>
                </Grid>

                <Grid item xs={8} style={{ marginLeft: "35px" }}>
                  <div className={classes.valuesContainer}>
                    <Controller
                      name="recalculationType"
                      id="recalculationType"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ onChange, value }) => (
                        <RadioGroup
                          name="recalculationType"
                          value={value}
                          onChange={onChange}
                          className={classes.valueTypeContainer}
                        >
                          <FormControlLabel
                            value="historically"
                            control={<Radio color="primary" />}
                            label="Historically"
                          />
                          <FormControlLabel
                            value="hereOnForth"
                            control={<Radio color="primary" />}
                            label="Here on forth"
                          />
                        </RadioGroup>
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div>
              <div className={classes.root}>
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={2}>
                    <Typography style={{ fontSize: 14, marginLeft: "10px" }}>
                      Cut Off Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ marginLeft: "25px", marginTop: "4px" }}
                  >
                    <Controller
                      control={control}
                      name="isCutOffDate"
                      id="isCutOffDate"
                      fullWidth
                      render={({ onChange, value }) => {
                        return (
                          <Switch
                            style={{ color: "#008080" }}
                            color="#008080"
                            checked={value}
                            onChange={(event) => onChange(event.target.checked)}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} style={{ marginLeft: "65px" }}>
                    {isCutOffDate ? (
                      <>
                        <Controller
                          control={control}
                          name="cutOffDate"
                          id="cutOffDate"
                          fullWidth
                          render={({ onChange, value }) => {
                            return (
                              <TextField
                                type="date"
                                id="date"
                                variant="outlined"
                                fullWidth
                                onChange={onChange}
                                value={value}
                                error={!!errors.cutOffDate}
                                helperText={errors?.cutOffDate?.message}
                              />
                            );
                          }}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={2}>
                    <Typography style={{ fontSize: 14, marginLeft: "10px" }}>
                      Tax Deducted
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ marginLeft: "25px", marginTop: "4px" }}
                  >
                    <Controller
                      control={control}
                      name="isTaxDeducted"
                      id="isTaxDeducted"
                      fullWidth
                      render={({ onChange, value }) => {
                        return (
                          <Switch
                            style={{ color: "#008080" }}
                            color="#008080"
                            checked={value}
                            onChange={(event) => onChange(event.target.checked)}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: "60px" }}>
                    {isTaxDeducted ? (
                      <div className={classes.valuesContainer}>
                        <Controller
                          name="taxDeductedValueType"
                          id="taxDeductedValueType"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          defaultValue="amount"
                          render={({ onChange, value }) => (
                            <RadioGroup
                              name="taxDeductedValueType"
                              value={value}
                              onChange={onChange}
                              className={classes.valueTypeContainer}
                            >
                              <FormControlLabel
                                value="amount"
                                control={<Radio color="primary" />}
                                label="Amount"
                              />
                              <FormControlLabel
                                value="percentage"
                                control={<Radio color="primary" />}
                                label="Percentage"
                              />
                            </RadioGroup>
                          )}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ marginLeft: "625px", marginTop: "-57px" }}
                  >
                    {isTaxDeducted ? (
                      <div className={classes.valuesContainer}>
                        <Controller
                          control={control}
                          name="taxDeductedValue"
                          id="taxDeductedValue"
                          fullWidth
                          render={({ onChange, value }) => {
                            return (
                              <TextField
                                size="small"
                                type="number"
                                id="taxDeductedValue"
                                placeholder="Value"
                                style={{ width: "200px" }}
                                variant="outlined"
                                fullWidth
                                onChange={onChange}
                                value={value}
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                  },
                                }}
                                error={!!errors.taxDeductedValue}
                                helperText={errors?.taxDeductedValue?.message}
                              />
                            );
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={2}>
                    <Typography style={{ fontSize: 14, marginLeft: "10px" }}>
                      Transaction Fee
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ marginLeft: "25px", marginTop: "4px" }}
                  >
                    <Controller
                      control={control}
                      name="isTransactionFee"
                      id="isTransactionFee"
                      fullWidth
                      render={({ onChange, value }) => {
                        return (
                          <Switch
                            style={{ color: "#008080" }}
                            color="#008080"
                            checked={value}
                            onChange={(event) => onChange(event.target.checked)}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} style={{ marginLeft: "60px" }}>
                    {isTransactionFee ? (
                      <div className={classes.valuesContainer}>
                        <Controller
                          name="transactionFeeValueType"
                          id="transactionFeeValueType"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          defaultValue="amount"
                          render={({ onChange, value }) => (
                            <RadioGroup
                              name="transactionFeeValueType"
                              value={value}
                              onChange={onChange}
                              className={classes.valueTypeContainer}
                            >
                              <FormControlLabel
                                value="amount"
                                control={
                                  <Radio value="amount" color="primary" />
                                }
                                label="Amount"
                              />
                              <FormControlLabel
                                value="percentage"
                                control={
                                  <Radio value="percentage" color="primary" />
                                }
                                label="Percentage"
                              />
                            </RadioGroup>
                          )}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ marginLeft: "625px", marginTop: "-57px" }}
                  >
                    {isTransactionFee ? (
                      <div className={classes.valuesContainer}>
                        <Controller
                          control={control}
                          name="transactionFeeValue"
                          id="transactionFeeValue"
                          fullWidth
                          render={({ onChange, value }) => {
                            return (
                              <TextField
                                size="small"
                                type="number"
                                id="transactionFeeValue"
                                placeholder="Value"
                                style={{ width: "200px" }}
                                variant="outlined"
                                fullWidth
                                onChange={onChange}
                                value={value}
                                InputProps={{
                                  inputProps: {
                                    min: 0,
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                  },
                                }}
                                error={!!errors.transactionFeeValue}
                                helperText={
                                  errors?.transactionFeeValue?.message
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid container spacing={2}>
                  <Grid style={{ marginTop: "10px" }} item xs={2}>
                    <Typography style={{ fontSize: 14, marginLeft: "10px" }}>
                      Partner Rev Share
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    style={{ marginLeft: "25px", marginTop: "2px" }}
                  >
                    <Controller
                      control={control}
                      name="isPartnerRevShare"
                      id="isPartnerRevShare"
                      fullWidth
                      render={({ onChange, value }) => {
                        return (
                          <Switch
                            style={{ color: "#008080" }}
                            color="#008080"
                            checked={value}
                            onChange={(event) => onChange(event.target.checked)}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>

            {isPartnerRevShare ? (
              <>
                <div>
                  <Grid style={{ marginTop: "20px" }} item xs={4}>
                    <Typography className={classes.inputFieldsTitle}>
                      Commission Structure
                    </Typography>
                    <Controller
                      control={control}
                      name="commissionType"
                      id="commissionType"
                      render={({ onChange, value }) => {
                        return (
                          <TextField
                            className={classes.selectionField}
                            select={!_.isEmpty(commissionOptions)}
                            id="commissionType"
                            variant="outlined"
                            label={value ? "" : "Select Commission Structure"}
                            fullWidth
                            MenuProps={MenuProps}
                            disabled={!_.isEmpty(commissionOptions)}
                            SelectProps={{
                              value: value,
                              renderValue: (selected) => {
                                const selectedResourceOptions =
                                  selected &&
                                  !_.isEmpty(commissionOptions) &&
                                  commissionOptions.filter(
                                    (commissionType) =>
                                      commissionType.value === selected
                                  )[0];

                                return (
                                  selectedResourceOptions &&
                                  selectedResourceOptions.label
                                );
                              },
                              onChange: onChange,
                              disabled: _.isEmpty(commissionOptions),
                            }}
                            error={!!errors.commissionType}
                            helperText={errors?.commissionType?.message}
                          >
                            {!_.isEmpty(commissionOptions) &&
                              commissionOptions.map((commissionType) => (
                                <MenuItem
                                  key={commissionType.value}
                                  value={commissionType.value}
                                  onClick={() =>
                                    commissionType.value ===
                                      commissionTypes.tieredType &&
                                    append({
                                      from: "",
                                      to: "",
                                      value: "",
                                    })
                                  }
                                >
                                  <ListItemText
                                    primary={commissionType.label}
                                  />
                                </MenuItem>
                              ))}
                          </TextField>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {commissionType === commissionTypes.flatType && (
                      <div className={classes.valuesContainer}>
                        <div style={{ width: "50%" }}>
                          <Card className={classes.root}>
                            <CardContent>
                              <Grid>
                                <Controller
                                  name="flatTypeValueType"
                                  id="flatTypeValueType"
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  defaultValue="amount"
                                  render={({ onChange, value }) => (
                                    <RadioGroup
                                      name="flatTypeValueType"
                                      value={value}
                                      onChange={onChange}
                                      className={classes.valueTypeContainer}
                                    >
                                      <FormControlLabel
                                        value="amount"
                                        control={<Radio color="primary" />}
                                        label="Amount"
                                      />
                                      <FormControlLabel
                                        value="percentage"
                                        control={<Radio color="primary" />}
                                        label="Percentage"
                                      />
                                    </RadioGroup>
                                  )}
                                />
                              </Grid>

                              <Controller
                                control={control}
                                name="flatTypeValue"
                                id="flatTypeValue"
                                fullWidth
                                render={({ onChange, value }) => {
                                  return (
                                    <TextField
                                      size="small"
                                      type="text"
                                      id="flatTypeValue"
                                      placeholder="Value"
                                      style={{ width: "200px" }}
                                      variant="outlined"
                                      fullWidth
                                      onChange={onChange}
                                      value={value}
                                      InputProps={{
                                        inputProps: {
                                          min: 0,
                                        },
                                      }}
                                      error={!!errors.flatTypeValue}
                                      helperText={
                                        errors?.flatTypeValue?.message
                                      }
                                    />
                                  );
                                }}
                              />
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    )}

                    {commissionType === commissionTypes.tieredType && (
                      <div className={classes.root}>
                        <div>
                          <Card className={classes.root}>
                            <CardContent>
                              <Typography
                                style={{ fontSize: 14, marginTop: "5px" }}
                                className={classes.pos}
                              >
                                Clip Commission
                              </Typography>
                              <div>
                                <Grid container spacing={1}>
                                  <Grid xs={12}>
                                    <div>
                                      <Grid container spacing={0.5}>
                                        <Grid
                                          item
                                          xs={3}
                                          style={{ marginTop: "12px" }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              style={{
                                                fontSize: 14,
                                                marginLeft: "12px",
                                              }}
                                            >
                                              From
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ marginLeft: "5px" }}
                                            xs={12}
                                          >
                                            <div
                                              className={
                                                classes.valuesContainer
                                              }
                                            >
                                              <Controller
                                                name="clipCommissionTypes.from"
                                                id="clipCommissionTypes.from"
                                                control={control}
                                                rules={{
                                                  required: true,
                                                }}
                                                defaultValue="amount"
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => (
                                                  <RadioGroup
                                                    name="clipCommissionTypes.from"
                                                    value={value}
                                                    onChange={onChange}
                                                    className={
                                                      classes.valueTypeContainer
                                                    }
                                                  >
                                                    <FormControlLabel
                                                      value="amount"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Amount"
                                                    />
                                                    <FormControlLabel
                                                      value="percentage"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Percentage"
                                                    />
                                                  </RadioGroup>
                                                )}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={3}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              style={{
                                                fontSize: 14,
                                                marginLeft: "20px",
                                              }}
                                            >
                                              To
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ marginLeft: "15px" }}
                                            xs={12}
                                          >
                                            <div
                                              className={
                                                classes.valuesContainer
                                              }
                                            >
                                              <Controller
                                                name="clipCommissionTypes.to"
                                                id="clipCommissionTypes.to"
                                                control={control}
                                                rules={{
                                                  required: true,
                                                }}
                                                defaultValue="amount"
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => (
                                                  <RadioGroup
                                                    name="clipCommissionTypes.to"
                                                    value={value}
                                                    onChange={onChange}
                                                    className={
                                                      classes.valueTypeContainer
                                                    }
                                                  >
                                                    <FormControlLabel
                                                      value="amount"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Amount"
                                                    />
                                                    <FormControlLabel
                                                      value="percentage"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Percentage"
                                                    />
                                                  </RadioGroup>
                                                )}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={3}
                                          style={{ marginTop: "10px" }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              style={{
                                                fontSize: 14,
                                                marginLeft: "20px",
                                              }}
                                            >
                                              Values
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ marginLeft: "15px" }}
                                            xs={12}
                                          >
                                            <div
                                              className={
                                                classes.valuesContainer
                                              }
                                            >
                                              <Controller
                                                name="clipCommissionTypes.value"
                                                id="clipCommissionTypes.value"
                                                control={control}
                                                rules={{
                                                  required: true,
                                                }}
                                                defaultValue="amount"
                                                render={({
                                                  onChange,
                                                  value,
                                                }) => (
                                                  <RadioGroup
                                                    name="clipCommissionTypes.value"
                                                    value={value}
                                                    onChange={onChange}
                                                    className={
                                                      classes.valueTypeContainer
                                                    }
                                                  >
                                                    <FormControlLabel
                                                      value="amount"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Amount"
                                                    />
                                                    <FormControlLabel
                                                      value="percentage"
                                                      control={
                                                        <Radio color="primary" />
                                                      }
                                                      label="Percentage"
                                                    />
                                                  </RadioGroup>
                                                )}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Controller
                                            control={control}
                                            name="clipCommision"
                                            id="clipCommision"
                                            fullWidth
                                            render={({ value }) => {
                                              return (
                                                <>
                                                  {fields.map((item, index) => (
                                                    <TieredTest
                                                      key={item.id}
                                                      handleRemoveClick={remove}
                                                      clipCommissionArray={
                                                        fields
                                                      }
                                                      control={control}
                                                      errors={errors}
                                                      index={index}
                                                      clipCommision={item}
                                                      register={register}
                                                    />
                                                  ))}
                                                </>
                                              );
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Button
                                            style={{
                                              marginTop: "12px",
                                              marginLeft: "30px",
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disableElevation
                                            id="clipCommision"
                                            fullWidth
                                            onClick={async () => {
                                              await control.trigger();

                                              if (
                                                !errors.clipCommision?.length
                                              ) {
                                                append({
                                                  from: "",
                                                  to: "",
                                                  value: "",
                                                });
                                              }
                                            }}
                                            error={!!errors.clipCommision}
                                            helperText={
                                              errors?.clipCommision?.message
                                            }
                                          >
                                            <Add />
                                            ADD
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      </div>
                    )}
                  </Grid>
                </div>
              </>
            ) : (
              ""
            )}

            <Grid container spacing={4} style={{ marginTop: "10px" }}>
              <Grid item xs={12}>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
};

export default ProductCatalogFormComponent;

import React from 'react';
import {
	makeStyles,
	Typography,
	Dialog,
	DialogContent,
	useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const MediaPreviewModal = ({
	open,
	handleClose,
	merchandiseName,
	mediaPreviewTypeName
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const styles = useStyles();
	return (
		<Dialog
			className={styles.DialogContainer}
			open={open}
			onClose={handleClose}
			maxWidth={false}
			fullScreen={isMobile}
		>
			<DialogContent className={styles.DialogContentContainer}>
				<Typography gutterBottom variant='h5' component='h2'>
					{`${merchandiseName || ''} ${mediaPreviewTypeName.name && '-'} ${
						mediaPreviewTypeName.name
					}`}
				</Typography>
				{open === 'Video' && (
					<video
						key={mediaPreviewTypeName.file}
						disablePictureInPicture
						disableRemotePlayback
						autoPlay
						controls
						playsInline
						className={styles.uploadedMedia}
					>
						<source src={mediaPreviewTypeName.file} type='video/mp4' />
					</video>
				)}
				{open === 'Image' && (
					<img
						src={mediaPreviewTypeName.file}
						alt={'mediaPreviewImage'}
						className={styles.uploadedMedia}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
	DialogContainer: {
		[breakpoints.up('md')]: {
			'& .MuiDialog-container > .MuiPaper-root': {
				minWidth: spacing(60),
				maxWidth: spacing(80),
				maxHeight: '90%'
			}
		},
		[breakpoints.down('sm')]: {
			'& .MuiDialog-container > .MuiPaper-root >': {
				height: '100%',
				width: '100%'
			}
		}
	},
	DialogContentContainer: {
		maxHeight: '80%'
	},
	DialogContainerRoot: {
		width: '40%',
		[breakpoints.down('sm')]: {
			width: '100%',
			height: '100%'
		}
	},
	uploadedMedia: {
		width: '100%',
		maxHeight: '80%'
	}
}));

export default MediaPreviewModal;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
	DialogContainer: {
		[breakpoints.up('md')]: {
			'& .MuiDialog-container > .MuiPaper-root': {
				minWidth: 'auto',
				maxWidth: spacing(80),
				padding: spacing(0, 4),
				maxHeight: '90%'
			}
		},
		[breakpoints.down('sm')]: {
			'& .MuiDialog-container > .MuiPaper-root >': {
				height: '100%',
				width: '100%'
			}
		}
	},
	DialogContentContainer: {
		maxHeight: '80%',
		display: 'flex',
		justifyContent: 'center',
		padding: spacing(3, 1)
	},
	DialogContainerRoot: {
		width: '40%',
		[breakpoints.down('sm')]: {
			width: '100%',
			height: '100%'
		}
	},
	uploadedMedia: {
		width: '100%',
		maxHeight: '80%'
	},
	colorPicker: {
		width: '20vw !important',
		[breakpoints.down('sm')]: {
			width: '80vw !important'
		}
	}
}));

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "../../../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  table: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    padding: "10px",
  },
}));

export const TieredTest = ({
  errors,
  index,
  clipCommision,
  clipCommissionArray,
  handleRemoveClick,
  register,
  values,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ paddingRight: "5px" }}>
        <Grid container xs={12}>
          <Grid
            item
            xs={3}
            style={{
              marginTop: "12px",
              marginLeft: "10px",
            }}
          >
            <TextField
              inputRef={register()}
              name={`clipCommision[${index}].from`}
              size="small"
              type="text"
              id="clipCommision.from"
              defaultValue={clipCommision.from}
              placeholder="Value"
              style={{ width: "180px" }}
              variant="outlined"
              fullWidth
              inputProps={{
                min:
                  index > 0
                    ? parseFloat(clipCommissionArray[index - 1].to) + 1
                    : 1,
              }}
              error={!!errors?.clipCommision?.[index]?.from}
              helperText={errors?.clipCommision?.[index]?.from?.message}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              marginTop: "10px",
              marginLeft: "10px",
            }}
          >
            <TextField
              name={`clipCommision[${index}].to`}
              size="small"
              type="text"
              inputRef={register()}
              defaultValue={clipCommision.to}
              id="clipCommision.to"
              placeholder="Value"
              style={{ width: "180px" }}
              variant="outlined"
              inputProps={{
                min:
                  index > 0
                    ? parseFloat(clipCommissionArray[index - 1].to) + 1
                    : parseFloat(clipCommissionArray[index].from) + 1,
              }}
              fullWidth
              error={!!errors?.clipCommision?.[index]?.to}
              helperText={errors?.clipCommision?.[index]?.to?.message}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              marginTop: "10px",
            }}
          >
            <TextField
              name={`clipCommision[${index}].value`}
              inputRef={register()}
              defaultValue={clipCommision.value}
              size="small"
              type="text"
              id="clipCommision.value"
              placeholder="Value"
              style={{ width: "180px" }}
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0.0,
                  inputmode: "numeric",
                },
              }}
              error={!!errors?.clipCommision?.[index]?.value}
              helperText={errors?.clipCommision?.[index]?.value?.message}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              marginTop: "10px",
            }}
          >
            {clipCommissionArray.length > 1 && (
              <Button
                style={{ marginTop: "8px" }}
                variant="contained"
                color="danger"
                disableElevation
                onClick={() => handleRemoveClick(index)}
              >
                <DeleteIcon />
                Remove
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { TableRow, TableCell, makeStyles, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { darken } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Image } from '@material-ui/icons';
import { DarkTooltip } from '../../../components/Tooltip/TooltipComponent';
import MediaPreviewModal from '../../../components/MediaPreviewModal/MediaPreviewModal';
import Button from '../../../components/CustomButtons/Button';

import MerchandiseDeleteModal from './merchandiseDeleteModal/MerchandiseDeleteModal';
import IncludedInstitutionsModal from './includedInstitutionsModal/IncludedInstitutionsModal';

const MerchandiseRow = ({
	id,
	name,
	image,
	source,
	price,
	includedInstitutions,
	handleMerchandiseDelete,
	handleIncludeInstitutions,
	institutionList
}) => {
	const classes = useStyles();
	const [openMediaPreviewModal, setOpenMediaPreviewModal] = useState();
	const [openDeleteModal, setOpenDeleteModal] = useState(null);
	const [openIncludedInstitutionModal, setOpenIncludedInstitutionModal] =
		useState(null);
	const [mediaPreviewTypeName, setMediaPreviewTypeName] = useState({});

	return (
		<>
			<TableRow>
				<TableCell className={classes.tableCellName}>{name}</TableCell>
				<TableCell className={classes.tableCellSource}>{source}</TableCell>
				<TableCell className={classes.tableCellSource}>{`${price} $`}</TableCell>
				<TableCell className={classes.tableCellImage}>
					<DarkTooltip placement='top' title='Merchandise Image'>
						<IconButton
							onClick={() => {
								setOpenMediaPreviewModal('Image');
								setMediaPreviewTypeName({
									name: 'Merchandise Image',
									file: image
								});
							}}
							disabled={!image}
							style={{ textDecoration: 'none', color: image ? 'teal' : 'darkgray' }}
						>
							<Image />
						</IconButton>
					</DarkTooltip>
				</TableCell>

				<TableCell className={classes.tableCellSource}>
					<Link
						style={{ textDecoration: 'none', color: 'teal', marginRight: '8px' }}
						to={`merchandise/${id}`}
					>
						<Button color='success'>
							<EditIcon />
							Edit
						</Button>
					</Link>
					<Button
						color='danger'
						onClick={() => setOpenDeleteModal({ id, name, image, source, price })}
					>
						<DeleteIcon />
						Delete
					</Button>
					<Button
						color='success'
						style={{ textDecoration: 'none', marginLeft: '8px' }}
						onClick={() =>
							setOpenIncludedInstitutionModal({ includedInstitutions, id })
						}
					>
						Included Institutions
					</Button>
				</TableCell>
			</TableRow>
			{openMediaPreviewModal && (
				<MediaPreviewModal
					open={openMediaPreviewModal}
					handleClose={() => setOpenMediaPreviewModal(false)}
					mediaPreviewTypeName={mediaPreviewTypeName}
					merchandiseName={name}
				/>
			)}
			{!!openDeleteModal && (
				<MerchandiseDeleteModal
					open={!!openDeleteModal}
					handleClose={() => setOpenDeleteModal(null)}
					merchandise={openDeleteModal}
					handleDelete={handleMerchandiseDelete}
				/>
			)}
			{!!openIncludedInstitutionModal && (
				<IncludedInstitutionsModal
					open={!!openIncludedInstitutionModal}
					handleClose={() => setOpenIncludedInstitutionModal(null)}
					includedInstitutions={openIncludedInstitutionModal}
					institutionList={institutionList}
					handleIncludeInstitutions={handleIncludeInstitutions}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles({
	tableCellName: {
		maxWidth: '25%',
		minWidth: '20%',
		fontSize: '11px'
	},
	tableCell: {
		maxWidth: '20%',
		minWidth: '15%',
		fontSize: '11px'
	},
	tableCellSource: {
		minWidth: '15%',
		fontSize: '11px'
	},
	activity: {
		maxWidth: '10%'
	},
	tableCellImage: {
		minWidth: '10%'
	},
	tableRow: {
		fontSize: '11px'
	},
	colorCellContainer: {
		display: 'flex',
		gap: '5px'
	},
	colorBox: {
		width: '20px',
		height: '20px',
		cursor: 'pointer'
	}
});

export default MerchandiseRow;

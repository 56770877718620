import * as yup from 'yup';

const ALLOWED_ASPECT_RATIO = 16 / 9;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const handleGetFilesFromEvent = async (event) => {
	const file =
		event.target?.files?.[0] || event.dataTransfer?.items?.[0].getAsFile();
	const promises = [];

	if (file) {
		if (file.type === 'video/mp4') {
			const promise = new Promise((resolve) => {
				const video = document.createElement('video');
				video.src = URL.createObjectURL(file);

				video.onloadedmetadata = () => {
					file.width = video.videoWidth;
					file.height = video.videoHeight;
					resolve(file);
				};
			});
			promises.push(await promise);
			return Promise.all(promises);
		}

		const promise = new Promise((resolve) => {
			const image = new Image();
			image.src = URL.createObjectURL(file);
			image.onload = () => {
				if (file) {
					file.width = image.width;
					file.height = image.height;
					resolve(file);
				}
			};
		});
		promises.push(await promise);
	}
	return Promise.all(promises);
};

export const handleFileValidation = (file) => {
	const ratio = file.width / file.height;
	if (ratio.toFixed(2) !== ALLOWED_ASPECT_RATIO.toFixed(2)) {
		return {
			code: 'custom',
			message: 'Please upload the file in 16:9 aspect ratio'
		};
	}
	return null;
};

export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 350
		}
	}
};

export const templateSchema = yup.object().shape({
	name: yup.string().required('You must enter template name'),
	categoryId: yup.mixed().required('You must select the category'),
	video: yup.mixed().optional('Sample video is required'),
	zipFile: yup.mixed().optional('Template zip file is required'),
	thumbnail: yup.mixed().optional('Thumbnail is required'),
	ceremonyThumbnail: yup.mixed().optional('Ceremony thumbnail is required'),
	studentThumbnail: yup.mixed().optional('Student thumbnail is required')
});

import React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemText, Icon, makeStyles } from '@material-ui/core';
import styles from '../../../assets/jss/material-dashboard-react/components/sidebarStyle';

export default ({ name, to, icon }) => {
	const classes = useStyles();
	const MenuIcon = icon;
	return (
		<Link to={to} className={`${classes.item}`}>
			<ListItem button className={`${classes.itemLink}`}>
				{MenuIcon && (
					<Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
						<MenuIcon />
					</Icon>
				)}
				<ListItemText className={classes.submenu}>{name}</ListItemText>
			</ListItem>
		</Link>
	);
};

const useStyles = makeStyles(styles);

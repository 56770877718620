import React from 'react';
import { makeStyles } from '@material-ui/core';

export default ({ msg }) => {
  const classes = useStyles();
  return <div className={classes.error}>{msg}</div>;
};

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
});

import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import TableFormatter from '../../components/TableFormatter';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { allActivityTracks } from '../../dataService/allActivityTracks';
import ActivityRow from './ActivityRow';
import NoContentCard from '../../components/NoContentCard';
import { useHistory } from 'react-router-dom';
import Loading from '../../components/Loading';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import Filter from './Filter';
import moment from 'moment';

export default () => {
	const classes = useStyles();
	const { push } = useHistory();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [pageNumber, setPageNumber] = useState(0);
	const [resultCount, setResultCount] = useState(50);
	const [pageCount, setPageCount] = useState(0);
	const [rowsCount, setRowsCount] = useState(0);
	const [ip, setIp] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [activityCodeList, setActivityCodeList] = useState([]);
	const [flag, setFlag] = useState(true);
	let date = moment.utc().format('YYYY-MM-DDThh:mm');
	let prevDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DDThh:mm');
	const tableHead = [
		{ name: 'Date & Time', align: 'left' },
		{ name: 'Activity Code', align: 'left' },
		{ name: 'Email', align: 'left' },
		{ name: 'Institution', align: 'left' },
		{ name: 'Channel', align: 'left' },
		{ name: 'Meta Data', align: 'center' }
	];
	const [searchTerms, setSearchTerms] = useState({
		_entity: 'ActivityTrack',
		_select: '*',
		startDate: moment(prevDate).utc().format('YYYY-MM-DD hh:mm'),
		endDate: moment(date).utc().format('YYYY-MM-DD hh:mm')
	});

	const [searchActivityTerms, setSearchActivityTerms] = useState({
		_entity: 'Activity',
		_select: '*',
		_page: 1
	});

	const createData = (
		id,
		activityCode,
		email,
		channel,
		createdAt,
		institution,
		data,
		userName
	) => {
		return {
			id,
			activityCode,
			email,
			channel,
			createdAt,
			institution,
			data,
			userName
		};
	};

	const createUserRows = useCallback(async () => {
		const res = await allActivityTracks(searchTerms);
		setIsLoading(true);
		const rows = [];

		if (res.status !== 'success') return [];

		res.data.forEach((item) => {
			const {
				id,
				activityCode,
				email,
				channel,
				createdAt,
				institution,
				data,
				userName
			} = item;
			rows.push(
				createData(
					id,
					activityCode,
					email,
					channel,
					createdAt,
					institution,
					data,
					userName
				)
			);
		});

		const totalRows = Math.ceil(res.meta.totalCount);
		setRowsCount(totalRows);
		const count = Math.ceil(res.meta.totalCount / 50);
		setRows(rows);
		setIsLoading(false);
		setPageNumber(res.meta.page);
		setResultCount(res.meta.resultCount);
		setPageCount(count);
	}, [searchTerms]);

	useEffect(() => {
		createUserRows();
	}, [searchTerms]);

	const prevPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: searchTerms._page - 1 }
		});
	};

	const nextPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: pageNumber + 1 }
		});
	};

	const lastPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: (searchTerms._page = pageCount) }
		});
	};

	const firstPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: (searchTerms._page = 1) }
		});
	};
	return (
		<>
			<React.Fragment>
				<h3>Activity Log</h3>
				<Filter searchTerms={searchTerms} setSearchTerms={setSearchTerms} />
			</React.Fragment>
			{isLoading && (
				<Loading className={classes.topSpacing} boxHeight='calc(100vh - 120px)' />
			)}

			{!isLoading && !rows.length && (
				<Grid container justify='flex-end' className={classes.topSpacing}>
					<Grid item md={12}>
						<NoContentCard message='No activities found' />
						<Pagination
							nextPage={nextPage}
							prevPage={prevPage}
							pageNumber={pageNumber}
							resultCount={resultCount}
							lastPage={lastPage}
							firstPage={firstPage}
							rowsCount={rowsCount}
						/>
					</Grid>
				</Grid>
			)}

			{rows.length > 0 && !isLoading && (
				<TableFormatter
					tableHead={tableHead}
					height='calc(100vh - 150px)'
					pagination={() => (
						<Pagination
							nextPage={nextPage}
							prevPage={prevPage}
							pageNumber={pageNumber}
							resultCount={resultCount}
							pageCount={pageCount}
							lastPage={lastPage}
							firstPage={firstPage}
							rowsCount={rowsCount}
						/>
					)}
				>
					{rows.map((row) => (
						<MuiThemeProvider theme={theme}>
							<ActivityRow key={row.id} {...row} />
						</MuiThemeProvider>
					))}
				</TableFormatter>
			)}
		</>
	);
};

const Pagination = ({
	nextPage,
	prevPage,
	pageNumber,
	pageCount,
	resultCount,
	lastPage,
	firstPage,
	rowsCount
}) => {
	const classes = useStyles();
	return (
		<div className={classes.pagination}>
			<GridContainer direction='row' justify='center'>
				<GridItem md={1}>
					<Button
						onClick={firstPage}
						color='transparent'
						round
						small
						disabled={pageNumber === 1}
					>
						<FirstPageIcon />
					</Button>
				</GridItem>
				{pageNumber === 1 ? null : (
					<GridItem>
						<Button
							onClick={prevPage}
							color='transparent'
							round
							small
							disabled={pageNumber === 1}
						>
							<KeyboardArrowLeft />
						</Button>
					</GridItem>
				)}
				<p>
					<strong>{pageNumber}</strong>
				</p>
				<GridItem md={1}>
					<Button
						onClick={nextPage}
						color='transparent'
						round
						small
						disabled={resultCount < 50}
					>
						<KeyboardArrowRight />
					</Button>
				</GridItem>
				<GridItem>
					<Button
						onClick={lastPage}
						color='transparent'
						round
						small
						disabled={resultCount < 50}
					>
						{pageCount}
						<LastPageIcon />
					</Button>
				</GridItem>
			</GridContainer>
			<GridContainer justify='center'>
				<GridItem>
					{pageNumber * 50 <= rowsCount ? (
						<p>
							<span style={{ color: 'gray', fontWeight: 'bold' }}>
								{' '}
								{pageNumber * 50 - 49} - {pageNumber * 50} of {rowsCount}
							</span>
						</p>
					) : (
						<p>
							<span>
								{' '}
								{pageNumber * 50 - 49} - {rowsCount} of {rowsCount}
							</span>
						</p>
					)}
				</GridItem>
			</GridContainer>
		</div>
	);
};

const useStyles = makeStyles({
	topSpacing: {
		marginTop: '30px'
	},
	marginNone: {
		margin: 0
	},
	wrapperCard: {
		marginBottom: 0
	},
	pagination: {
		paddingTop: '15px'
	},
	tableContainer: {
		height: 'calc(100vh - 373px)',
		'& .MuiTableCell-stickyHeader': {
			background: 'white'
		}
	},

	notFoundContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center',
		height: 'calc(100% - 57px)'
	}
});

const theme = createTheme({
	overrides: {
		MuiTableCell: {
			root: {
				paddingTop: 4,
				paddingBottom: 4,
				paddingRight: 5
			}
		}
	}
});

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
	DialogContainer: {
		[breakpoints.up('md')]: {
			'& .MuiDialog-container > .MuiPaper-root': {
				minWidth: spacing(40),
				maxWidth: spacing(50),
				maxHeight: '90%',
				textAlign: 'center'
			}
		},
		[breakpoints.down('sm')]: {
			'& .MuiDialog-container > .MuiPaper-root >': {
				height: '100%',
				width: '100%'
			}
		}
	},
	DialogContentContainer: {
		maxHeight: '80%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: '20px',
		padding: spacing(4, 5)
	},
	DialogContainerRoot: {
		width: '40%',
		[breakpoints.down('sm')]: {
			width: '100%',
			height: '100%'
		}
	},
	actionContainer: {
		display: 'flex',
		justifyContent: 'center',
		gap: '20px'
	},
	yesButton: {
		backgroundColor: 'red',
		color: 'white',
		fontWeight: '400'
	}
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
	DialogContainer: {
		[breakpoints.up('md')]: {
			'& .MuiDialog-container > .MuiPaper-root': {
				minWidth: '40%',
				maxWidth: '60%',
				maxHeight: '90%',
				textAlign: 'center'
			}
		},
		[breakpoints.down('sm')]: {
			'& .MuiDialog-container > .MuiPaper-root >': {
				height: '100%',
				width: '100%'
			}
		}
	},
	DialogContentContainer: {
		maxHeight: '80%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: spacing(2)
	},
	DialogContainerRoot: {
		width: '40%',
		[breakpoints.down('sm')]: {
			width: '100%',
			height: '100%'
		}
	},
	actionContainer: {
		display: 'flex',
		justifyContent: 'center',
		gap: '20px'
	},
	yesButton: {
		backgroundColor: 'red',
		color: 'white',
		fontWeight: '400'
	},
	formControl: {
		margin: spacing(1),
		display: 'flex',
		flexDirection: 'column',
		gap: spacing(2),
		textAlign: 'left'
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: 2
	},
	includedInstitutionListContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '5px',
		padding: spacing(2),
		textAlign: 'left',
		width: 'fit-content'
	},
	includedInstitutionsContainer: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: spacing(1),
		textAlign: 'left'
	},
	includedInstitutionCard: {
		display: 'flex',
		textAlign: 'left',
		border: '1px solid teal',
		padding: spacing(0.5, 1),
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	deleteIcon: {
		padding: spacing(0.5),
		color: 'red',
		marginLeft: spacing(1)
	},
	noIncludedInstitution: {
		paddingTop: spacing(2)
	}
}));

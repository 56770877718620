import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Sidebar from "../src/components/SideBar/Sidebar";
import styles from "./assets/jss/material-dashboard-react/layouts/adminStyle";
import Activities from "./pages/Activities";
import ActivityDetails from "./pages/ActivityDetails";
import Categories from "./pages/Category";
import AddCategory from "./pages/Category/Add";
import Templates from "./pages/Templates";
import EditTemplate from "./pages/AddEditTemplates/EditTemplate";
import EditCategory from "./pages/Category/Edit";
import AddNewTemplate from "./pages/AddEditTemplates/AddTemplate";
import AddMerchandise from "./pages/Merchandise/AddEditMerchandise/AddMerchandise";
import EditMerchandise from "./pages/Merchandise/AddEditMerchandise/EditMerchandise";
import MerchandiseList from "./pages/Merchandise/MerchandiseList";
import ProductCatalog from "./pages/ProductCatalog";
import AddProductCatalog from "./pages/ProductCatalog/AddEditProductCatalog/AddProductCatalog";
import EditProductCatalog from "./pages/ProductCatalog/AddEditProductCatalog/EditProductCatalog";
import EventDetails from './pages/EventDetails';

const useStyles = makeStyles(styles);

const Routes = (props) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const mainPanel = React.createRef();

  return (
    <div className={classes.wrapper}>
      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="red"
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {/* eslint-disable */}
              <Route
                exact
                {...props}
                path={"/"}
                component={() => <Redirect to="/activities" />}
              />

              <Route
                exact
                {...props}
                component={AddMerchandise}
                path="/merchandise/create"
              />
              <Route
                exact
                {...props}
                component={EditMerchandise}
                path="/merchandise/:id"
              />

              <Route
                exact
                {...props}
                component={Activities}
                path="/activities"
              />
              <Route
                exact
                {...props}
                component={ActivityDetails}
                path="/activityDetails"
              />

              <Route
                exact
                {...props}
                component={AddCategory}
                path="/addCategory"
              />

              <Route
                exact
                {...props}
                component={Categories}
                path="/categories"
              />
              <Route
                exact
                {...props}
                component={EditCategory}
                path="/category/:id"
              />

              <Route exact {...props} component={Templates} path="/templates" />
              <Route
                exact
                {...props}
                component={EditTemplate}
                path="/template/:id"
              />
              <Route
                exact
                {...props}
                component={AddNewTemplate}
                path="/addTemplate"
              />

              <Route
                exact
                {...props}
                component={ProductCatalog}
                path="/productCatalog"
              />

              <Route
                exact
                {...props}
                component={AddProductCatalog}
                path="/productCatalog/create"
              />

              <Route
                exact
                {...props}
                component={EditProductCatalog}
                path="/productCatalog/:id"
              />

              <Route
                exact
                {...props}
                component={AddMerchandise}
                path="/merchandise/create"
              />

              <Route
                exact
                {...props}
                component={MerchandiseList}
                path="/merchandise"
              />
              <Route
                exact
                {...props}
                component={AddMerchandise}
                path="/merchandise/create"
              />
              <Route
                exact
                {...props}
                component={EditMerchandise}
                path="/merchandise/:id"
              />
              <Route exact {...props} component={EventDetails} path='/event-details' />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Routes;

import React from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Button from '../../components/CustomButtons/Button';

const CategoryRow = ({
    id,
    categoryName,
    source,
    action,
}) => {
    const classes = useStyles();
    return (
        <TableRow>
            <TableCell className={classes.tableCell} width="40%">{categoryName}</TableCell>
            <TableCell className={classes.tableCell} width="50%">{source}</TableCell>
            <TableCell className={classes.tableCell} width="10%"><Link style={{textDecoration:'none'}}  to={`category/${id}`}><Button color="success"><EditIcon />Edit</Button></Link></TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles({
    tableCell: {
        maxWidth: '20%',
        minWidth: '15%',
        fontSize: '11px'
    },
    activity: {
        maxWidth: '10%'
    },
    institute: {
        width: '5%'
    },
    tableRow: {
        fontSize: '11px'
    },
});

export default CategoryRow;
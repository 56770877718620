import * as Yup from "yup";

export const yupSchemaObject = {
  categoryName: Yup.string("Enter a Category Name").required(
    "Category is mandatory"
  ),
  source: Yup.string("Enter Source").required("Source is mandatory"),
};
export const initialValues = {
  categoryName: "",
  source: "",
  video: [],
};

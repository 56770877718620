import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import TableFormatter from '../../components/TableFormatter';
import Button from '../../components/CustomButtons/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { allActivities } from '../../dataService/getAllActivityCodes';
import ActivityRow from './ActivityRow';
import NoContentCard from '../../components/NoContentCard';
import Loading from '../../components/Loading';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
export default () => {
	const classes = useStyles();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const tableHead = [
		{ name: 'Activity Code', align: 'left' },
		{ name: 'Title', align: 'left' }
	];
	const [searchTerms, setSearchTerms] = useState({
		_entity: 'Activity',
		_select: '*',
		_page: 1
	});

	const createData = (id, activityCode, title) => {
		return {
			id,
			activityCode,
			title
		};
	};

	const createUserRows = useCallback(async () => {
		const res = await allActivities(searchTerms);
		setIsLoading(true);
		const rows = [];

		if (res.status !== 'success') return [];

		res.data.forEach((item) => {
			const { id, activityCode, title } = item;
			rows.push(createData(id, activityCode, title));
		});
		setRows(rows);
		setIsLoading(false);
	}, [searchTerms]);

	useEffect(() => {
		createUserRows();
	}, [searchTerms]);

	return (
		<>
			<React.Fragment>
				<h3>Activity Codes</h3>
			</React.Fragment>
			{isLoading && (
				<Loading className={classes.topSpacing} boxHeight='calc(100vh - 120px)' />
			)}

			{!isLoading && !rows.length && (
				<Grid container justify='flex-end' className={classes.topSpacing}>
					<Grid item md={12}>
						<NoContentCard message='No activities found' />
					</Grid>
				</Grid>
			)}

			{rows.length > 0 && !isLoading && (
				<TableFormatter tableHead={tableHead} height='calc(100vh - 150px)'>
					{rows.map((row) => (
						<MuiThemeProvider theme={theme}>
							<ActivityRow key={row.id} {...row} />
						</MuiThemeProvider>
					))}
				</TableFormatter>
			)}
		</>
	);
};

const useStyles = makeStyles({
	topSpacing: {
		marginTop: '30px'
	},
	marginNone: {
		margin: 0
	},
	wrapperCard: {
		marginBottom: 0
	},
	pagination: {
		paddingTop: '15px'
	},
	tableContainer: {
		height: 'calc(100vh - 373px)',
		'& .MuiTableCell-stickyHeader': {
			background: 'white'
		}
	},
	notFoundContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center',
		height: 'calc(100% - 57px)'
	}
});

const theme = createTheme({
	overrides: {
		MuiTableCell: {
			root: {
				paddingTop: 4,
				paddingBottom: 4,
				paddingRight: 5
			}
		}
	}
});

import * as yup from 'yup';
import {
	users,
	email as emails,
	password as passwords
} from '../../config/env.json';

export const defaultValues = {
	email: '',
	password: ''
};

export const schema = yup.object().shape({
	email: yup
		.string()
		.email('You must enter a valid email')
		.required('You must enter a email'),
	password: yup.string().max(255).required('Please enter your password.')
});

export const checkValidEmailPassword = (email, password) => {
	const emailList = emails.split(',');
	const passwordList = passwords.split(',');
	const user = emailList.filter((item) => item === email);
	if (!!!user.length) return false;
	const emailIndex = emailList.indexOf(email);
	if (password === passwordList[emailIndex]) return true;
	return false;
};

/*eslint no-restricted-globals: ["error", "event"]*/
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  ListItemText,
  CircularProgress,
  Card,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { AddAlert } from "@material-ui/icons";
import { editMerchandise } from "../../../../dataService/editMerchandise";
import { createMerchandise } from "../../../../dataService/createMerchandise";
import DropzoneComponent from "../../../../components/DropzoneComponent/DropzoneComponent";
import { useStyles } from "./MerchandiseFormComponent.styles";
import ColorSelection from "./colorSelection/ColorSelection";
import {
  handleGetFilesFromEvent,
  MenuProps,
  merchandiseSchema,
} from "./MerchandiseFormComponent.utils";

const MerchandiseFormComponent = ({
  isLoading,
  merchandiseInfo = {},
  isEditPage = false,
  setIsLoading,
  id,
  setSnackbarConfig,
}) => {
  const { push } = useHistory();
  const classes = useStyles();

  const sourceOptions = [
    { id: "KUDOS", name: "Kudos" },
    { id: "PRODUCER", name: "Producer" },
  ];

  useEffect(() => {
    if (!_.isEmpty(merchandiseInfo)) {
      setValue("name", merchandiseInfo.name);
      setValue("source", merchandiseInfo.source);
      setValue("price", merchandiseInfo.price);
      setValue("image", merchandiseInfo.image);
    }
  }, [merchandiseInfo]);

  const defaultValues = {
    name: "",
    source: "",
    price: null,
    image: "",
  };

  const { register, handleSubmit, setValue, control, formState, getValues } =
    useForm({
      shouldUnregister: false,
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues,
      resolver: yupResolver(merchandiseSchema),
    });
  const { errors } = formState;

  const handleFormSubmit = async (values) => {
    isEditPage ? handleFormEditSubmit(values) : handleFormAddSubmit(values);
  };

  const handleFormEditSubmit = async (values) => {
    if (values.image.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Image is required",
        icon: AddAlert,
        color: "danger",
      });
    } else {
      setIsLoading(true);

      const obj = {
        name: values.name,
        source: values.source,
        price: values.price,
        image: _.isArray(values.image) ? values.image[0] : values.image,
      };
      const res = await editMerchandise(id, obj);
      if (res.status === 200) {
        setSnackbarConfig({
          open: true,
          message: "Merchandise edited successfully!",
          icon: AddAlert,
          color: "success",
        });
        setTimeout(() => {
          push("/merchandise");
        }, 1000);
      } else {
        setSnackbarConfig({
          open: true,
          message: "Error in update",
          icon: AddAlert,
          color: "error",
        });
      }
    }
  };

  const handleFormAddSubmit = async (values) => {
    if (values.image.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Image is required",
        icon: AddAlert,
        color: "danger",
      });
    } else {
      setIsLoading(true);
      const obj = {
        name: values.name,
        source: values.source,
        price: values.price,
        image: values.image[0],
      };
      const res = await createMerchandise(obj);
      if (res.status === 200) {
        setSnackbarConfig({
          open: true,
          message: "Merchandise creation successful!",
          icon: AddAlert,
          color: "success",
        });
        setTimeout(() => {
          push("/merchandise");
        }, 1000);
      } else {
        setSnackbarConfig({
          open: true,
          message: "Merchandise creation unsuccessful!",
          icon: AddAlert,
          color: "error",
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.hederContainer}>
        <Typography variant="h4" className={classes.hederText}>
          {isEditPage ? "Edit Merchandise" : "Create Merchandise"}
        </Typography>
      </Card>

      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} title="Loading...." />
        </div>
      ) : (
        <div className={classes.merchandiseFormContainer}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Name
                </Typography>
                <Controller
                  label="Merchandise Name"
                  id="name"
                  name="name"
                  control={control}
                  variant="outlined"
                  fullWidth
                  render={({ onChange, value }, {}) => {
                    return (
                      <TextField
                        id="name"
                        variant="outlined"
                        placeholder="Merchandise Name"
                        MenuProps={MenuProps}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <Typography className={classes.inputFieldsTitle}>
                  Source
                </Typography>
                <Controller
                  control={control}
                  name="source"
                  id="source"
                  render={({ onChange, value }) => {
                    return (
                      <TextField
                        className={classes.selectionField}
                        select={!_.isEmpty(sourceOptions)}
                        id="source"
                        variant="outlined"
                        label={value ? "" : "source"}
                        fullWidth
                        MenuProps={MenuProps}
                        disabled={!_.isEmpty(sourceOptions)}
                        SelectProps={{
                          value: value,
                          renderValue: (selected) => {
                            const selectedSource =
                              selected &&
                              !_.isEmpty(sourceOptions) &&
                              sourceOptions.filter(
                                (source) => source.id === selected
                              )[0];
                            return selectedSource && selectedSource.name;
                          },
                          onChange: onChange,
                          disabled: _.isEmpty(sourceOptions),
                        }}
                        error={!!errors.source}
                        helperText={errors?.source?.message}
                      >
                        {!_.isEmpty(sourceOptions) &&
                          sourceOptions.map((source) => (
                            <MenuItem key={source.id} value={source.id}>
                              <ListItemText primary={source.name} />
                            </MenuItem>
                          ))}
                      </TextField>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Price (USD)
                </Typography>
                <Controller
                  label="Merchandise price (USD)"
                  id="price"
                  name="price"
                  control={control}
                  variant="outlined"
                  fullWidth
                  render={({ onChange, value }, {}) => {
                    return (
                      <TextField
                        id="price"
                        variant="outlined"
                        type="number"
                        placeholder="Merchandise price (USD)"
                        MenuProps={MenuProps}
                        value={value}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">USD</InputAdornment>
                          ),
                        }}
                        onChange={onChange}
                        fullWidth
                        error={!!errors.price}
                        helperText={errors?.price?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Upload Merchandise Image
                </Typography>
                <div className={classes.controllerContainer}>
                  <Controller
                    control={control}
                    id="image"
                    name="image"
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      const isMediaString = _.isString(value);
                      const mediaUrl = isMediaString
                        ? value
                        : URL.createObjectURL(value[0]);
                      return !value ? (
                        <DropzoneComponent
                          errors={!!errors.image}
                          helperText={errors?.image?.message}
                          dropzoneName="image"
                          handleChange={onChange}
                          acceptFiles="image/*"
                          register={register}
                          dropzoneHeader="Drag and drop image here or click"
                          getFilesFromEvent={handleGetFilesFromEvent}
                          key="image"
                        />
                      ) : (
                        <div className={classes.mediaContainer}>
                          <img
                            src={mediaUrl}
                            alt="image"
                            className={classes.media}
                          />
                          <Button
                            onClick={() => setValue("image", "")}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              {/* <Grid item xs={6}>
								{colors && (
									<ColorSelection
										colors={colors}
										control={control}
										errorMsg={errors?.colors?.message}
									/>
								)}
							</Grid> */}
              <Grid item xs={12}>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
};

export default MerchandiseFormComponent;

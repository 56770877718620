export default (state = {}, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      // return Object.assign(state, { signedIn: true });
      return { ...state, ...{ signedIn: true } };
    case "REQUEST_LOGOUT":
      return { ...state, ...{ signedIn: false } };
    default:
      return state;
  }
};

import React from 'react';
import {
	Typography,
	Dialog,
	DialogContent,
	useMediaQuery,
	Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from './MerchandiseDeleteModal.styles';

const MerchandiseDeleteModal = ({
	open,
	handleClose,
	handleDelete,
	merchandise
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const styles = useStyles();

	return (
		<Dialog
			className={styles.DialogContainer}
			open={open}
			onClose={handleClose}
			maxWidth={false}
			fullScreen={isMobile}
		>
			<DialogContent className={styles.DialogContentContainer}>
				<Typography gutterBottom variant='h5' component='h2'>
					{`Do you want to delete "${merchandise.name}" merchandise ?`}
				</Typography>
				<div className={styles.actionContainer}>
					<Button variant='outlined' color='primary' onClick={handleClose}>
						No
					</Button>
					<Button
						variant='contained'
						className={styles.yesButton}
						onClick={() => handleDelete(merchandise.id)}
					>
						Yes
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default MerchandiseDeleteModal;

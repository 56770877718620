import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	FormControl,
	Select,
	MenuItem,
	InputLabel
} from '@material-ui/core';
import { get } from 'lodash';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Error from '../../../components/Error';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const CategoryForm = ({ formik, isEdit, cat }) => {
	const classes = useStyle();
	const { touched, errors } = formik;

	const multipleSelectPropsProcess = (props) => {
		return { ...props, ...{ value: props.value || [] } };
	};
	return (
		<Card>
			<h3 style={{ paddingLeft: '20px' }}>
				<b>Category Information</b>
			</h3>

			<GridItem>
				<GridContainer>
					<GridItem xs={6}>
						<CustomInput
							labelText='Category Name *'
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								type: 'text',
								...formik.getFieldProps('categoryName'),

								error: !!(touched.categoryName && errors.categoryName)
							}}
						/>
						{touched.categoryName && errors.categoryName && (
							<Error msg={errors.categoryName} />
						)}
					</GridItem>

					<GridItem xs={6}>
						<FormControl fullWidth className={classes.formControl}>
							<InputLabel id='demo-simple-select-label'>Source *</InputLabel>
							<Select
								labelId='demo-simple-select-label'
								id='demo-simple-select'
								label={cat}
								disabled={isEdit}
								{...formik.getFieldProps('source')}
							>
								<MenuItem value='KUDOS'>KUDOS</MenuItem>
								<MenuItem value='PRODUCER'>PRODUCER</MenuItem>
							</Select>
						</FormControl>

						{get(errors, 'source', '') && get(touched, 'source', '') && (
							<Error msg={get(errors, 'source', '')} />
						)}
					</GridItem>
				</GridContainer>
			</GridItem>
		</Card>
	);
};

const useStyle = makeStyles((theme) => ({
	formControl: {
		width: '100%',
		marginTop: '27px'
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200
	}
}));

export default CategoryForm;

import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const deleteMerchandise = async (id) => {
	try {
		const res = await axiosInstanceForTemplate.delete(`/merchandise/${id}`);
		return res;
	} catch (error) {
		return error.response.data;
	}
};

import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';
import {
	KeyboardArrowRight,
	KeyboardArrowLeft,
	AddAlert
} from '@material-ui/icons';
import TableFormatter from '../../../components/TableFormatter';
import Button from '../../../components/CustomButtons/Button';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { allMerchandises } from '../../../dataService/getAllMerchandise';
import { deleteMerchandise } from '../../../dataService/deleteMerchandiseById';
import { updateIncludedInstitutionMerchandise } from '../../../dataService/updateIncludedInstitutionMerchandise';
import { getInstitutionsFromClippingPlatform } from '../../../dataService/getInstitutionsFromClippingPlatform';

import MerchandiseRow from './MerchandiseRow';
import NoContentCard from '../../../components/NoContentCard';
import Snackbar from '../../../components/Snackbar/Snackbar';
import Loading from '../../../components/Loading';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import Filter from './Filter';

import moment from 'moment';

export default () => {
	const classes = useStyles();
	const [rows, setRows] = useState([]);
	const [institutionList, setInstitutionList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [pageNumber, setPageNumber] = useState(0);
	const [resultCount, setResultCount] = useState(50);
	const [pageCount, setPageCount] = useState(0);
	const [rowsCount, setRowsCount] = useState(0);
	const { push } = useHistory();
	const [selectedSource, setSelectedSource] = useState('');
	const [searchValue, setSearchValue] = useState('');

	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	const isUserIn = window.localStorage.getItem('userIn');
	if (!isUserIn) {
		push('/login');
	}

	const tableHead = [
		{ name: 'Name', align: 'left', width: '25%' },
		{ name: 'Source', align: 'left', width: '15%' },
		{ name: 'Price', align: 'left', width: '15%' },
		{ name: 'Image', align: 'left', width: '15%' },
		{ name: 'Action', align: 'left', width: '30%' }
	];
	const [searchTerms, setSearchTerms] = useState({
		_select: '*'
	});

	const createUserRows = useCallback(async () => {
		setIsLoading(true);
		const res = await allMerchandises(searchTerms);
		if (res.status === 200) {
			const count = Math.ceil(res.data.meta.totalCount / 50);
			const totalRows = Math.ceil(res.data.meta.totalCount);
			setRowsCount(totalRows);
			setRows(res.data.data);
			setIsLoading(false);
			setPageNumber(res.data.meta.page);
			setResultCount(res.data.meta.resultCount);
			setPageCount(count);
		}
		setIsLoading(false);
	}, [searchTerms]);

	useEffect(() => {
		createUserRows();
	}, [searchTerms]);

	const handleChange = (event) => {
		setSelectedSource(event.target.value);
	};

	const handleSearchValueChange = (event) => {
		setSearchValue(event.target.value);
	};

	const sourceOptions = [
		{ id: 'KUDOS', name: 'Kudos' },
		{ id: 'PRODUCER', name: 'Producer' }
	];

	const HandleReset = () => {
		setSelectedSource('');
		setSearchValue('');
	};

	const handleMerchandiseDelete = async (id) => {
		const res = await deleteMerchandise(id);
		if (res.status === 200) {
			setSnackbarConfig({
				open: true,
				message: res.data.message,
				icon: AddAlert,
				color: 'success'
			});
			createUserRows();
		}
	};

	const handleIncludeInstitutionMerchandise = async (id, data) => {
		const res = await updateIncludedInstitutionMerchandise(id, data);
		if (res.status === 200) {
			setSnackbarConfig({
				open: true,
				message: res.data.message,
				icon: AddAlert,
				color: 'success'
			});
			createUserRows();
		}
	};

	const getInstitutionsListFromClippingPlatform = async () => {
		const res = await getInstitutionsFromClippingPlatform();
		if (res.rows) {
			setInstitutionList(res.rows);
		}
	};

	useEffect(() => {
		getInstitutionsListFromClippingPlatform();
	}, []);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};
		if (selectedSource) {
			updatedSearchTerms['source'] = selectedSource;
		} else {
			delete updatedSearchTerms['source'];
		}
		setSearchTerms(updatedSearchTerms);
	}, [selectedSource]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};
		if (searchValue.length > 1) {
			updatedSearchTerms['search'] = searchValue;
		} else {
			delete updatedSearchTerms['search'];
		}
		setSearchTerms(updatedSearchTerms);
	}, [searchValue]);

	const prevPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: searchTerms._page - 1 }
		});
	};

	const nextPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: pageNumber + 1 }
		});
	};

	const lastPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: (searchTerms._page = pageCount) }
		});
	};

	const firstPage = () => {
		setSearchTerms({
			...searchTerms,
			...{ _page: (searchTerms._page = 1) }
		});
	};

	const RedirectToCreateMerchandise = () => {
		push(`/merchandise/create`);
	};

	return (
		<>
			<React.Fragment>
				<h3>Merchandise</h3>

				<Button
					color='success'
					small
					onClick={() => RedirectToCreateMerchandise()}
					disabled={isLoading}
				>
					Add New Merchandise
				</Button>
				<Filter
					HandleReset={HandleReset}
					selectedSource={selectedSource}
					sourceOptions={sourceOptions}
					handleChange={handleChange}
					handleSearchValueChange={handleSearchValueChange}
					searchValue={searchValue}
				/>
			</React.Fragment>

			{isLoading && (
				<Loading className={classes.topSpacing} boxHeight='calc(100vh - 120px)' />
			)}

			{!isLoading && !rows.length && (
				<Grid container justify='flex-end' className={classes.topSpacing}>
					<Grid item md={12}>
						<NoContentCard message='No Merchandise found' />
					</Grid>
				</Grid>
			)}

			{rows.length > 0 && !isLoading && (
				<TableFormatter
					tableHead={tableHead}
					height='calc(100vh - 150px)'
					pagination={() => (
						<Pagination
							nextPage={nextPage}
							prevPage={prevPage}
							pageNumber={pageNumber}
							resultCount={resultCount}
							pageCount={pageCount}
							lastPage={lastPage}
							firstPage={firstPage}
							rowsCount={rowsCount}
						/>
					)}
				>
					<MuiThemeProvider theme={theme}>
						{rows.map((merchandise) => (
							<MerchandiseRow
								key={merchandise.id}
								id={merchandise.id}
								name={merchandise.name}
								image={merchandise.image}
								source={merchandise.source}
								price={merchandise.price}
								institutionList={institutionList}
								includedInstitutions={merchandise.includedInstitutions}
								handleMerchandiseDelete={handleMerchandiseDelete}
								handleIncludeInstitutions={handleIncludeInstitutionMerchandise}
							/>
						))}
					</MuiThemeProvider>
				</TableFormatter>
			)}
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</>
	);
};

const Pagination = ({
	nextPage,
	prevPage,
	pageNumber,
	pageCount,
	resultCount,
	lastPage,
	firstPage,
	rowsCount
}) => {
	const classes = useStyles();
	return (
		<div className={classes.pagination}>
			<GridContainer direction='row' justify='center'>
				<GridItem md={1}>
					<Button
						onClick={firstPage}
						color='transparent'
						round
						small
						disabled={pageNumber === 1}
					>
						<FirstPageIcon />
					</Button>
				</GridItem>
				{pageNumber === 1 ? null : (
					<GridItem>
						<Button
							onClick={prevPage}
							color='transparent'
							round
							small
							disabled={pageNumber === 1}
						>
							<KeyboardArrowLeft />
						</Button>
					</GridItem>
				)}
				<p>
					<strong>{pageNumber}</strong>
				</p>
				<GridItem md={1}>
					<Button
						onClick={nextPage}
						color='transparent'
						round
						small
						disabled={resultCount < 50}
					>
						<KeyboardArrowRight />
					</Button>
				</GridItem>
				<GridItem>
					<Button
						onClick={lastPage}
						color='transparent'
						round
						small
						disabled={resultCount < 50}
					>
						{pageCount}
						<LastPageIcon />
					</Button>
				</GridItem>
			</GridContainer>
			<GridContainer justify='center'>
				<GridItem>
					{pageNumber * 50 <= rowsCount ? (
						<p>
							<span style={{ color: 'gray', fontWeight: 'bold' }}>
								{' '}
								{pageNumber * 50 - 49} - {pageNumber * 50} of {rowsCount}
							</span>
						</p>
					) : (
						<p>
							<span>
								{' '}
								{pageNumber * 50 - 49} - {rowsCount} of {rowsCount}
							</span>
						</p>
					)}
				</GridItem>
			</GridContainer>
		</div>
	);
};

const useStyles = makeStyles({
	topSpacing: {
		marginTop: '30px'
	},
	marginNone: {
		margin: 0
	},
	wrapperCard: {
		marginBottom: 0
	},
	pagination: {
		paddingTop: '15px'
	},
	tableContainer: {
		height: 'calc(100vh - 373px)',
		'& .MuiTableCell-stickyHeader': {
			background: 'white'
		}
	},

	notFoundContainer: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center',
		height: 'calc(100% - 57px)'
	}
});

const theme = createTheme({
	palette: {
		primary: {
			main: '#008080'
		}
	},
	overrides: {
		MuiTableCell: {
			root: {
				paddingTop: 4,
				paddingBottom: 4,
				paddingRight: 5
			}
		}
	}
});

import React from "react";
import { makeStyles, Grid, TextField } from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";

const FilterEvents = ({ handleSearchValueChange, searchValue }) => {
  const classes = useStyles();

  return (
    <Card>
      <CardBody>
        <Grid container spacing={2}>
          <Grid md={4} item>
            <TextField
              value={searchValue}
              onChange={handleSearchValueChange}
              className={classes.fullWidth}
              label="Search by Event ID"
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export default FilterEvents;

import axios from 'axios';
import {
	clippingPlatformUrl,
	clippingPlatformUserName,
	clippingPlatformPassword
} from '../../config/env';

const axiosInstanceForClippingPlatform = axios.create({
	baseURL: clippingPlatformUrl,
	auth: {
		username: clippingPlatformUserName,
		password: clippingPlatformPassword
	}
});

export default axiosInstanceForClippingPlatform;

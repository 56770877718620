import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  AddAlert,
} from "@material-ui/icons";
import TableFormatter from "../../components/TableFormatter";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import ProductCatalogRow from "./ProductCatalogRow";
import NoContentCard from "../../components/NoContentCard";
import Loading from "../../components/Loading";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { allProductCatalog } from "../../dataService/getAllProductCatalog";
import Snackbar from "@material-ui/core/Snackbar";

export default () => {
  const classes = useStyles();
  const { push } = useHistory();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [rowsCount, setRowsCount] = useState(0);

  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: "",
    color: "success",
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const tableHead = [
    { name: "Type", align: "left", width: "5%" },
    { name: "Client Type", align: "left", width: "10%" },
    { name: "Institute Name", align: "left", width: "10%" },
    { align: "left", width: "0%" }, //removed Pricing name here
    { name: "Currency", align: "left", width: "5%" },
    { name: "Cut of Date", align: "left", width: "5%" },
    // { name: "Tax Deducted", align: "left", width: "5%" },
    // { name: "Tax Deducted Value", align: "left", width: "5%" },
    // { name: "Transaction Fee", align: "left", width: "5%" },
    // { name: "Transaction Fee Value", align: "left", width: "5%" },
    // { name: "Commission Structure", align: "left", width: "10%" },
    // { name: "Type", align: "left", width: "5%" },
    // { name: "Flat Value", align: "left", width: "5%" },
    // { name: "Tiered Values", align: "left", width: "5%" },
    { name: "Action", align: "left", width: "5%" },
  ];
  const [searchTerms, setSearchTerms] = useState({
    _select: "*",
  });

  const createUserRows = useCallback(async () => {
    setIsLoading(true);
    const res = await allProductCatalog(searchTerms);
    if (res.status === 200) {
      const count = Math.ceil(res.data.meta.totalCount / 50);
      const totalRows = Math.ceil(res.data.meta.totalCount);
      setRowsCount(totalRows);
      setRows(res.data.data);
      setIsLoading(false);
      setPageNumber(res.data.meta.page);
      setResultCount(res.data.meta.resultCount);
      setPageCount(count);
    }
    setIsLoading(false);
  }, [searchTerms]);

  useEffect(() => {
    createUserRows();
  }, [searchTerms]);

  const prevPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: searchTerms._page - 1 },
    });
  };

  const nextPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: searchTerms._page + 1 },
    });
  };

  const lastPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: (searchTerms._page = pageCount) },
    });
  };

  const firstPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: (searchTerms._page = 1) },
    });
  };

  const addProductCatalog = () => {
    push("/productCatalog/create");
  };

  return (
    <>
      <React.Fragment>
        <h3>Product Catalog</h3>
        <Button
          variant="contained"
          color="success"
          onClick={() => addProductCatalog()}
          disabled={isLoading}
        >
          Add Product Catalog
        </Button>
      </React.Fragment>
      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && !rows.length && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No Product Catalog found" />
          </Grid>
        </Grid>
      )}

      {rows.length > 0 && !isLoading && (
        <TableFormatter
          tableHead={tableHead}
          height="calc(100vh - 150px)"
          pagination={() => (
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
              rowsCount={rowsCount}
            />
          )}
        >
          <MuiThemeProvider theme={theme}>
            {rows.map((productCatalog) => (
              <ProductCatalogRow
                key={productCatalog.id}
                id={productCatalog.id}
                resourceType={productCatalog.resourceType}
                clientType={productCatalog.clientType}
                institutionName={
                  productCatalog.institutionName
                  // &&
                  // productCatalog.institutionName.filter((x) => x.name)
                }
                currency={productCatalog.currency}
                // price={productCatalog.pricing}
                cutOffDate={productCatalog.cutOffDate}
                // taxDeducted={productCatalog.transactionFeeValueType}
                // taxDeductedValue={productCatalog.taxDeductedValue}
                // transactionFee={productCatalog.transactionFeeValueType}
                // transactionFeeValue={productCatalog.transactionFeeValue}
                // partnerRevShare={productCatalog.partnerRevShareValueType}
                // commissionStructureValueType={
                // productCatalog.commissionStructureValue.type
                // }
                // commissionStructureValues={
                // productCatalog.commissionStructureValue.value
                // }
              />
            ))}
          </MuiThemeProvider>
        </TableFormatter>
      )}

      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="tr"
      />
    </>
  );
};

const Pagination = ({
  nextPage,
  prevPage,
  pageNumber,
  pageCount,
  resultCount,
  lastPage,
  firstPage,
  rowsCount,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
        <GridItem md={1}>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        {pageNumber === 1 ? null : (
          <GridItem>
            <Button
              onClick={prevPage}
              color="transparent"
              round
              small
              disabled={pageNumber === 1}
            >
              <KeyboardArrowLeft />
            </Button>
          </GridItem>
        )}
        <p>
          <strong>{pageNumber}</strong>
        </p>
        <GridItem md={1}>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 50}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 50}
          >
            {pageCount}
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          {pageNumber * 50 <= rowsCount ? (
            <p>
              <span style={{ color: "gray", fontWeight: "bold" }}>
                {" "}
                {pageNumber * 50 - 49} - {pageNumber * 50} of {rowsCount}
              </span>
            </p>
          ) : (
            <p>
              <span>
                {" "}
                {pageNumber * 50 - 49} - {rowsCount} of {rowsCount}
              </span>
            </p>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: "30px",
  },
  marginNone: {
    margin: 0,
  },
  wrapperCard: {
    marginBottom: 0,
  },
  pagination: {
    paddingTop: "15px",
  },
  tableContainer: {
    height: "calc(100vh - 373px)",
    "& .MuiTableCell-stickyHeader": {
      background: "white",
    },
  },
  notFoundContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    height: "calc(100% - 57px)",
  },
});

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});

import React from 'react';
import {
	makeStyles,
	withStyles,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer
} from '@material-ui/core';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardBody from '../Card/CardBody';

const TableFormatter = ({
	children,
	tableHead,
	height,
	pagination: Pagination
}) => {
	const classes = useStyles();
	return (
		<Card className={classes.wrapperCard}>
			<CardBody>
				<GridContainer>
					<GridItem xs={12}>
						<TableContainer className={classes.tableContainer} style={{ height }}>
							<Table className={classes.table} stickyHeader>
								<TableHead>
									<TableRow>
										{tableHead &&
											tableHead.map(({ align, name, width }) => (
												<StyledTableCell
													style={{
														width: width ? width : 'auto',
														fontWeight: 'bolder'
													}}
													key={name}
													align={align}
												>
													{name}
												</StyledTableCell>
											))}
									</TableRow>
								</TableHead>
								<TableBody>{children}</TableBody>
							</Table>
						</TableContainer>
					</GridItem>
				</GridContainer>
				{Pagination && <Pagination />}
			</CardBody>
		</Card>
	);
};

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		paddingTop: '20px',
		paddingBottom: '20px'
	},
	body: {
		fontSize: 14
	}
}))(TableCell);

const useStyles = makeStyles({
	topSpacing: {
		marginTop: '30px'
	},
	wrapperCard: {
		marginTop: '30px',
		marginBottom: 0
	},
	tableContainer: {
		height: 'calc(100vh - 154px)',
		'& .MuiTableCell-stickyHeader': {
			background: '#008080'
		}
	}
});

export default TableFormatter;

import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const allCategories = async (params) => {
	try {
		const res = await axiosInstanceForTemplate.get(`/category`, {
			data: null
		});
		return res;
	} catch (error) {
		return error;
	}
};

import React, { useState, useEffect } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { AddAlert } from '@material-ui/icons';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { getMerchandiseById } from '../../../dataService/getMerchandiseById';
import MerchandiseFormComponent from './merchandiseFormComponent/MerchandiseFormComponent';

const EditMerchandise = ({
	match: {
		params: { id }
	}
}) => {
	const [merchandiseInfo, setMerchandiseInfo] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	useEffect(() => {
		setMerchandiseEditForm();
	}, []);

	const setMerchandiseEditForm = async () => {
		setIsLoading(true);
		const res = await getMerchandiseById(id);
		if (res.status === 200) {
			if (res.data.data) {
				setMerchandiseInfo(res.data.data);
			}
		}
		setIsLoading(false);
	};

	return (
		<MuiThemeProvider theme={theme}>
			<MerchandiseFormComponent
				id={id}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				isEditPage={true}
				merchandiseInfo={merchandiseInfo}
				setSnackbarConfig={setSnackbarConfig}
				snackbarConfig={snackbarConfig}
			/>
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</MuiThemeProvider>
	);
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#008080'
		}
	}
});

export default EditMerchandise;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, breakpoints }) => ({
	formContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		backgroundColor: 'teal'
	},
	header: {
		fontWeight: '400'
	},
	cardContainer: {
		padding: spacing(4),
		width: spacing(50),
		[breakpoints.down('xs')]: {
			width: '100%',
			margin: spacing(0, 2)
		}
	},
	fieldContainer: {
		marginTop: spacing(2)
	},
	logoContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	textFields: {
		width: '100%'
	},
	loginButton: {
		color: '#ffffff',
		backgroundColor: 'teal',
		textTransform: 'capitalize',
		borderRadius: spacing(1),
		width: '100%'
	},
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '100px 0px'
	},
	loader: {
		color: 'teal',
		textTransform: 'capitalize'
	}
}));

import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AddAlert } from '@material-ui/icons';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import Button from '../../components/CustomButtons/Button';
import Loading from '../../components/Loading';
import { updateCategory } from '../../dataService/updateCategory';
import CategoryForm from './formParts/CategoryForm';
import { getCategory } from '../../dataService/getCategory';
import { yupSchemaObject, initialValues } from './formSchema';

const EditCategory = ({
	match: {
		params: { id }
	}
}) => {
	const classes = useStyles();
	const { push } = useHistory();
	const [formValues, setFormValues] = useState(initialValues);
	const validationSchema = Yup.object(yupSchemaObject);
	const [isLoaded, setIsLoaded] = useState(false);
	const [cat, setCat] = useState('');
	const [submitDisable, setSubmitDisable] = useState(false);
	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	const setSchemaAndInitialValueForEditForm = async (id) => {
		const { data: formValues } = await getCategory(id, true);

		setFormValues(formValues.category);
		setCat(formValues.category.source);
		setIsLoaded(true);
	};

	useEffect(() => {
		setSchemaAndInitialValueForEditForm(id);
	}, []);

	const onSubmit = async (values) => {
		setSubmitDisable(true);

		const { status, message } = await updateCategory(id, values);

		if (status === 'success') {
			setSubmitDisable(false);
			setSnackbarConfig({
				open: true,
				message,
				icon: AddAlert,
				color: 'success'
			});
			setSchemaAndInitialValueForEditForm(id);
			setTimeout(() => {
				push('/categories');
			}, 1000);
		} else {
			setSubmitDisable(false);
			setSnackbarConfig({
				open: true,
				message,
				icon: AddAlert,
				color: 'danger'
			});
		}
	};

	if (!isLoaded) {
		return (
			<>
				<Card style={{ backgroundColor: 'teal' }}>
					<CardBody>
						<h3 style={{ color: '#ffffff' }}>
							<b>Edit Category</b>
						</h3>
					</CardBody>
				</Card>
				<Loading
					className={classes.pageTopSpacing}
					boxHeight='calc(100vh - 122px)'
				/>
			</>
		);
	}

	return (
		<>
			<Card style={{ backgroundColor: 'teal' }}>
				<CardBody>
					<h3 style={{ color: '#ffffff' }}>
						<b>Edit Category : {formValues.categoryName}</b>
					</h3>
				</CardBody>
			</Card>
			<Formik
				initialValues={formValues}
				enableReinitialize
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => (
					<form className={classes.pageTopSpacing} onSubmit={formik.handleSubmit}>
						<GridContainer>
							<GridItem xs={12}>
								<CategoryForm formik={formik} isEdit cat={cat} />

								{/* <Card>
                  <CardBody> */}
								<GridContainer>
									<GridItem>
										<Button
											disabled={
												submitDisable ||
												(formik.isValid && !formik.dirty) ||
												!formik.isValid
											}
											color='success'
											type='submit'
										>
											Update Category
										</Button>
									</GridItem>
								</GridContainer>
								{/* </CardBody>
                </Card> */}
							</GridItem>
						</GridContainer>
					</form>
				)}
			</Formik>
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</>
	);
};

const useStyles = makeStyles({
	pageTopSpacing: {
		marginTop: '30px'
	},
	blockSpacing: {
		marginTop: '60px'
	}
});

export default EditCategory;

import axiosInstanceForTemplate from "./axiosInstanceForTemplate"

export const getCategory = async (id) => {
  try {
    const res = await axiosInstanceForTemplate.get(`/category/${id}`, {
      data: null,
    })

    return res.data
  } catch (error) {
    return error
  }
}

export default { getCategory }

import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import { yupSchemaObject, initialValues } from './formSchema';
import GridItem from '../../components/Grid/GridItem';
import CardBody from '../../components/Card/CardBody';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import Snackbar from '../../components/Snackbar/Snackbar';
import Button from '../../components/CustomButtons/Button';
import { addCategory } from '../../dataService/addCategory';
import CategoryForm from './formParts/CategoryForm';

const AddNewActivity = () => {
	const classes = useStyles();
	const { push } = useHistory();
	const validationSchema = Yup.object(yupSchemaObject);

	const [submitDisable, setSubmitDisable] = useState(false);
	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	const onSubmit = async (values, { resetForm }) => {
		setSubmitDisable(true);

		const { status, message } = await addCategory(values);

		if (status === 'success') {
			setSubmitDisable(false);
			setSnackbarConfig({
				open: true,
				message: 'Category creation successful!',
				icon: AddAlert,
				color: 'success'
			});

			setTimeout(() => {
				push('/categories');
			}, 1000);
		} else {
			setSubmitDisable(false);
			setSnackbarConfig({
				open: true,
				message,
				icon: AddAlert,
				color: 'danger'
			});
		}
	};

	return (
		<>
			<Card style={{ backgroundColor: 'teal' }}>
				<CardBody>
					<h3 style={{ color: '#ffffff' }}>
						<b>Add New Category</b>
					</h3>
				</CardBody>
			</Card>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{(formik) => (
					<form className={classes.pageTopSpacing} onSubmit={formik.handleSubmit}>
						<GridContainer>
							<GridItem xs={12}>
								<CategoryForm formik={formik} />
								<GridContainer>
									<GridItem>
										<Button
											disabled={
												submitDisable ||
												(formik.isValid && !formik.dirty) ||
												!formik.isValid
											}
											color='success'
											type='submit'
										>
											Create Category
										</Button>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					</form>
				)}
			</Formik>
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</>
	);
};

const useStyles = makeStyles({
	pageTopSpacing: {
		marginTop: '30px'
	},
	blockSpacing: {
		marginTop: '60px'
	}
});

export default AddNewActivity;

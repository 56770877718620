import axios from "axios";
import { templateHost, templateToken } from "../config/env";

const headers = {
  "Content-Type": "application/json",
  Authorization: templateToken,
};

const axiosInstanceForTemplate = axios.create({
  baseURL: templateHost,
  headers,
});

export default axiosInstanceForTemplate;

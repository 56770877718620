import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Grid } from "@material-ui/core";
import {
  KeyboardArrowRight,
  KeyboardArrowLeft,
  AddAlert,
} from "@material-ui/icons";
import TableFormatter from "../../components/TableFormatter";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { allTemplates } from "../../dataService/getAllTemplates";
import TemplateRow from "./TemplateRow";
import NoContentCard from "../../components/NoContentCard";
import Snackbar from "../../components/Snackbar/Snackbar";
import Loading from "../../components/Loading";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import { allCategories } from "../../dataService/allCategories";
import Filter from "./Filter";

export default () => {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [resultCount, setResultCount] = useState(50);
  const [pageCount, setPageCount] = useState(0);
  const [rowsCount, setRowsCount] = useState(0);
  const { push } = useHistory();
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [isActive, setActive] = useState(true);

  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: "",
    color: "success",
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const isUserIn = window.localStorage.getItem("userIn");
  if (!isUserIn) {
    push("/login");
  }

  const tableHead = [
    { name: "Title", align: "left" },
    { name: "Thumbnails", align: "left" },
    { name: "Video", align: "left" },
    { name: "ZipFile", align: "left" },
    { name: "Source", align: "left" },
    { name: "Category ", align: "left" },
    { name: "Action", align: "left" },
  ];
  const [searchTerms, setSearchTerms] = useState({
    _entity: "Template",
    _select: "*",
    isActive: true,
  });

  const createUserRows = useCallback(async () => {
    setIsLoading(true);
    const res = await allTemplates(searchTerms);
    if (res.status === 200) {
      const count = Math.ceil(res.data.meta.totalCount / 50);
      const totalRows = Math.ceil(res.data.meta.totalCount);
      setRowsCount(totalRows);
      setRows(res.data.data);
      setIsLoading(false);
      setPageNumber(res.data.meta.page);
      setResultCount(res.data.meta.resultCount);
      setPageCount(count);
    }
    setIsLoading(false);
  }, [searchTerms]);

  useEffect(() => {
    createUserRows();
  }, [searchTerms]);

  const handleChange = (event) => {
    setCategoryId(event.target.value);
  };
  const getAllCategory = async () => {
    const res = await allCategories();
    if (res.status === 200) {
      setCategory(res.data.data);
    } else {
      push("/categories");
    }
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  const HandleReset = () => {
    setCategoryId([]);
    setActive(true);
  };

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };
    if (category.length > 0) {
      updatedSearchTerms["categoryId"] = categoryId;
    } else {
      delete updatedSearchTerms["categoryId"];
    }
    setSearchTerms(updatedSearchTerms);
  }, [categoryId]);

  useEffect(() => {
    const updatedSearchTerms = {
      ...searchTerms,
    };
    updatedSearchTerms["isActive"] = isActive;
    setSearchTerms(updatedSearchTerms);
  }, [isActive]);

  const prevPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: searchTerms._page - 1 },
    });
  };

  const nextPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: pageNumber + 1 },
    });
  };

  const lastPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: (searchTerms._page = pageCount) },
    });
  };

  const firstPage = () => {
    setSearchTerms({
      ...searchTerms,
      ...{ _page: (searchTerms._page = 1) },
    });
  };

  const RedirectToTemplates = () => {
    !!category.length
      ? push(`/addTemplate`)
      : setSnackbarConfig({
          open: true,
          message: "Please add category before the add new template",
          icon: AddAlert,
          color: "error",
        });
  };

  return (
    <>
      <React.Fragment>
        <h3>Templates</h3>

        <Button
          color="success"
          small
          onClick={() => RedirectToTemplates()}
          disabled={isLoading}
        >
          Add new Template
        </Button>
        <Filter
          HandleReset={HandleReset}
          categoryId={categoryId}
          category={category}
          handleChange={handleChange}
          setActive={setActive}
          isActive={isActive}
        />
      </React.Fragment>

      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && !rows.length && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No Templates found" />
          </Grid>
        </Grid>
      )}

      {rows.length > 0 && !isLoading && (
        <TableFormatter
          tableHead={tableHead}
          height="calc(100vh - 150px)"
          pagination={() => (
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              pageNumber={pageNumber}
              resultCount={resultCount}
              pageCount={pageCount}
              lastPage={lastPage}
              firstPage={firstPage}
              rowsCount={rowsCount}
            />
          )}
        >
          {rows.map((template) => (
            <MuiThemeProvider theme={theme}>
              <TemplateRow
                key={template.id}
                id={template.id}
                name={template.name}
                thumbnail={template.thumbnail}
                video={template.video}
                zipFile={template.zipFile}
                categories={category}
                categoryId={template.categoryId}
                source={template.source}
                studentThumbnail={template.studentThumbnail}
                ceremonyThumbnail={template.ceremonyThumbnail}
              />
            </MuiThemeProvider>
          ))}
        </TableFormatter>
      )}
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="tr"
      />
    </>
  );
};

const Pagination = ({
  nextPage,
  prevPage,
  pageNumber,
  pageCount,
  resultCount,
  lastPage,
  firstPage,
  rowsCount,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <GridContainer direction="row" justify="center">
        <GridItem md={1}>
          <Button
            onClick={firstPage}
            color="transparent"
            round
            small
            disabled={pageNumber === 1}
          >
            <FirstPageIcon />
          </Button>
        </GridItem>
        {pageNumber === 1 ? null : (
          <GridItem>
            <Button
              onClick={prevPage}
              color="transparent"
              round
              small
              disabled={pageNumber === 1}
            >
              <KeyboardArrowLeft />
            </Button>
          </GridItem>
        )}
        <p>
          <strong>{pageNumber}</strong>
        </p>
        <GridItem md={1}>
          <Button
            onClick={nextPage}
            color="transparent"
            round
            small
            disabled={resultCount < 50}
          >
            <KeyboardArrowRight />
          </Button>
        </GridItem>
        <GridItem>
          <Button
            onClick={lastPage}
            color="transparent"
            round
            small
            disabled={resultCount < 50}
          >
            {pageCount}
            <LastPageIcon />
          </Button>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          {pageNumber * 50 <= rowsCount ? (
            <p>
              <span style={{ color: "gray", fontWeight: "bold" }}>
                {" "}
                {pageNumber * 50 - 49} - {pageNumber * 50} of {rowsCount}
              </span>
            </p>
          ) : (
            <p>
              <span>
                {" "}
                {pageNumber * 50 - 49} - {rowsCount} of {rowsCount}
              </span>
            </p>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: "30px",
  },
  marginNone: {
    margin: 0,
  },
  wrapperCard: {
    marginBottom: 0,
  },
  pagination: {
    paddingTop: "15px",
  },
  tableContainer: {
    height: "calc(100vh - 373px)",
    "& .MuiTableCell-stickyHeader": {
      background: "white",
    },
  },

  notFoundContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    height: "calc(100% - 57px)",
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#008080",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 5,
      },
    },
  },
});

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	container: {
		cursor: 'pointer',
		width: '100%'
	},
	dropzoneContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		textAlign: 'center',
		width: '-webkit-fill-available',
		margin: 'auto',
		border: '2px dashed #e0e0e0'
	},
	dropzoneImage: {
		objectPosition: 'center',
		margin: 'auto'
	},
	dropzoneHeader: {
		fontFamily: 'sans-serif',
		marginTop: '10px',
		fontSize: '20px'
	},
	dropzoneSubHeader: {
		fontFamily: 'sans-serif',
		fontSize: '16px'
	},
	errorMessage: {
		fontFamily: 'sans-serif',
		marginTop: '20px',
		fontSize: '20px',
		color: '#FF0000'
	}
}));

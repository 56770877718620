import React from 'react';
import {
	makeStyles,
	Grid,
	FormControl,
	Select,
	MenuItem,
	Input,
	TextField,
	InputLabel
} from '@material-ui/core';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import Button from '../../../components/CustomButtons/Button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};
const Filter = ({
	HandleReset,
	sourceOptions,
	selectedSource,
	handleChange,
	handleSearchValueChange,
	searchValue
}) => {
	const classes = useStyles();

	return (
		<Card>
			<CardBody>
				<Grid container spacing={2}>
					<Grid md={4} item>
						<TextField
							value={searchValue}
							onChange={handleSearchValueChange}
							className={classes.fullWidth}
							label='Search by name, price'
						/>
					</Grid>
					<Grid item md={4}>
						<FormControl className={classes.fullWidth}>
							<InputLabel id='demo-mutiple-chip-label'>Select Source</InputLabel>
							<Select
								labelId='demo-mutiple-chip-label'
								id='demo-mutiple-chip'
								value={selectedSource}
								onChange={handleChange}
								input={<Input id='select-multiple-chip' />}
								MenuProps={MenuProps}
							>
								{sourceOptions.map((data) => (
									<MenuItem key={data.id} value={data.id}>
										{data.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid style={{ paddingTop: '15px' }} item md={4}>
						<Button color='success' onClick={HandleReset}>
							Reset
						</Button>
					</Grid>
				</Grid>
			</CardBody>
		</Card>
	);
};

const useStyles = makeStyles((theme) => ({
	topSpacing: {
		marginTop: '30px'
	},
	filterTitle: {
		margin: '0 0 15px 0'
	},
	marginNone: {
		margin: 0
	},
	fullWidth: {
		width: '100%'
	},
	formGroup: {
		'& label': {
			margin: 0
		}
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: 0,
		height: '23px'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	textField: {
		marginLeft: theme.spacing(0),
		marginRight: theme.spacing(0),
		width: 250
	},
	customDate: {
		height: '10px'
	}
}));

export default Filter;

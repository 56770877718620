import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const DarkTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.black,
		color: 'rgba(255, 255, 255, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		padding: '10px',
		margin: '0px'
	},
	arrow: {
		color: theme.palette.common.black
	}
}))(Tooltip);

import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const editTemplate = async (id, data) => {
	const formData = new FormData();
	formData.append('name', data.name);
	formData.append('thumbnail', data.thumbnail);
	formData.append('video', data.video);
	formData.append('zipFile', data.zipFile);
	formData.append('categoryId', data.categoryId);
	formData.append('ceremonyThumbnail', data.ceremonyThumbnail);
	formData.append('studentThumbnail', data.studentThumbnail);
	formData.append('isActive', data.isActive);

	try {
		const res = await axiosInstanceForTemplate.put(`/template/${id}`, formData);

		return res.data;
	} catch (error) {
		return error;
	}
};

export default { editTemplate };

import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import {
	Typography,
	Grid,
	Container,
	Button,
	makeStyles,
	MenuItem,
	Checkbox,
	ListItemText,
	CircularProgress,
	Card,
	CardContent
} from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CardBody from '../../components/Card/CardBody';
import { useFormContext, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { DropzoneDialog, DropzoneArea } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';
import { AddAlert, GetApp } from '@material-ui/icons';
import Snackbar from '../../components/Snackbar/Snackbar';
import { getTemplateInfo } from '../../dataService/getTemplateInfo';
import { allCategories } from '../../dataService/allCategories';
import { editTemplate } from '../../dataService/editTemplate';
import BackupIcon from '@material-ui/icons/Backup';
import { ReactComponent as DownloadIcon } from '../../assets/img/icon-list-download.svg';
import DropzoneComponent from '../../components/DropzoneComponent/DropzoneComponent';
import TemplateComponent from './templateComponent/TemplateComponent';

const EditTemplate = ({
	match: {
		params: { id }
	}
}) => {
	const { push } = useHistory();
	const [categories, setCategories] = useState([]);
	const [templatesInfo, setTemplatesInfo] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const getCategory = async () => {
		const res = await allCategories();
		if (res.status === 200) {
			setCategories(res.data.data);
		}
	};

	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	useEffect(() => {
		getCategory();
		setTemplateEditForm();
	}, []);

	const setTemplateEditForm = async () => {
		setIsLoading(true);
		const res = await getTemplateInfo(id);
		if (res.status === 200) {
			if (res.data.data.template) {
				setTemplatesInfo(res.data.data.template);
			}
		}
		setIsLoading(false);
	};

	return (
		<MuiThemeProvider theme={theme}>
			<TemplateComponent
				id={id}
				categories={categories}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				isEditPage={true}
				templateInfo={templatesInfo}
				setSnackbarConfig={setSnackbarConfig}
				snackbarConfig={snackbarConfig}
			/>
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</MuiThemeProvider>
	);
};

const theme = createTheme({
	palette: {
		primary: {
			main: '#008080'
		}
	}
});

export default EditTemplate;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Snackbar from '../../components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons';
import { allCategories } from '../../dataService/allCategories';
import TemplateComponent from './templateComponent/TemplateComponent';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		width: '100%'
	},
	dropzoneHeader: {
		color: '#42536b',
		fontSize: '14px',
		fontWeight: '400'
	}
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 350
		}
	}
};

export default function AddTemplate() {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(true);
	const [categories, setCategories] = useState([]);

	const getCategory = async () => {
		setIsLoading(true);
		const res = await allCategories();
		if (res.status === 200) {
			setCategories(res.data.data);
		}
		setIsLoading(false);
	};
	useEffect(() => {
		getCategory();
	}, []);

	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	const theme = createTheme({
		palette: {
			primary: {
				main: '#008080'
			}
		}
	});

	return (
		<MuiThemeProvider theme={theme}>
			<TemplateComponent
				categories={categories}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				isEditPage={false}
				setSnackbarConfig={setSnackbarConfig}
				snackbarConfig={snackbarConfig}
			/>
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</MuiThemeProvider>
	);
}

import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { useStyles } from "./IncludedInstitutionsModal.styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const IncludedInstitutionsModal = ({
  open,
  handleClose,
  institutionList,
  includedInstitutions: { includedInstitutions, id },
  handleIncludeInstitutions,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const styles = useStyles();

  const [selected, setSelected] = useState([]);

  const institutionOptions = institutionList.filter((item) => {
    return includedInstitutions.every((IncludedInstitution) => {
      return IncludedInstitution !== item.id;
    });
  });

  const includedInstitutionsDetails = institutionList.filter((item) => {
    return includedInstitutions.some((IncludedInstitution) => {
      return IncludedInstitution === item.id;
    });
  });

  const handleMerchandiseInstitution = async () => {
    const selectedInstitutionsIds = selected.map((item) => {
      return item.id;
    });
    const selectedInstitutions = [
      ...includedInstitutions,
      ...selectedInstitutionsIds,
    ];
    await handleIncludeInstitutions(id, selectedInstitutions);
  };

  const handleRemoveInstitutionFromMerchandise = async (institutionsId) => {
    const removedInstitutionsIds = includedInstitutions.filter((item) => {
      return item !== institutionsId;
    });
    const withoutRemovedInstitutions = [...removedInstitutionsIds];
    await handleIncludeInstitutions(id, withoutRemovedInstitutions);
  };

  return (
    <Dialog
      className={styles.DialogContainer}
      open={open}
      onClose={handleClose}
      maxWidth={false}
      fullScreen={isMobile}
    >
      <DialogContent className={styles.DialogContentContainer}>
        <Typography gutterBottom variant="h5" component="h2">
          {"Included institutions"}
        </Typography>
        <div className={styles.includedInstitutionsContainer}>
          <InputLabel id="mutiple-select-label">
            Included institutions
          </InputLabel>
          {includedInstitutionsDetails.length ? (
            <div className={styles.includedInstitutionListContainer}>
              {includedInstitutionsDetails.map((item, index) => (
                <div className={styles.includedInstitutionCard}>
                  <span key={index}>{item.name}</span>

                  <IconButton
                    className={styles.deleteIcon}
                    onClick={() =>
                      handleRemoveInstitutionFromMerchandise(item.id)
                    }
                  >
                    <Delete />
                  </IconButton>
                </div>
              ))}
            </div>
          ) : (
            <Typography
              gutterBottom
              variant="body2"
              className={styles.noIncludedInstitution}
            >
              {"No Included institutions"}
            </Typography>
          )}
        </div>

        <div className={styles.formControl}>
          <InputLabel id="mutiple-select-label">Select Institutions</InputLabel>
          <Autocomplete
            id="mutiple-selection"
            multiple
            value={selected}
            onChange={(_, event) => setSelected([...event])}
            autoHighlight
            options={institutionOptions}
            renderOption={(option) => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemText primary={option.name} />
              </MenuItem>
            )}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder="Institution Name"
              />
            )}
            component="div"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleMerchandiseInstitution()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IncludedInstitutionsModal;

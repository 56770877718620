import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		width: '100%'
	},
	container: {
		marginTop: '20px'
	},
	hederContainer: {
		backgroundColor: 'teal'
	},
	hederText: {
		color: '#ffffff',
		fontWeight: '600',
		padding: '25px',
		fontSize: '20px',
		fontFamily: 'sans-serif'
	},
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '100px 0px'
	},
	loader: {
		color: 'teal',
		textTransform: 'capitalize'
	},
	templatesFormContainer: {
		paddingTop: '30px',
		paddingLeft: '20px',
		paddingRight: '20px'
	},
	selectionField: {
		minWidth: '140px'
	},
	inputFieldsTitle: {
		color: '#42536b',
		paddingTop: '35px',
		fontSize: '14px',
		fontWeight: '400'
	},
	dropzoneHeader: {
		color: '#42536b',
		fontSize: '14px',
		fontWeight: '400'
	},
	controllerContainer: {
		paddingTop: '10px',
		color: 'teal',
		overflow: 'hidden'
	},
	mediaContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	media: {
		width: '-webkit-fill-available',
		objectFit: 'contain',
		height: '270px',
		backgroundColor: '#e0e0e0',
		border: '1px solid #020'
	},
	removeButton: {
		color: 'teal',
		textTransform: 'capitalize'
	},
	zipFile: {
		width: '-webkit-fill-available',
		height: '270px',
		backgroundColor: '#e0e0e0',
		border: '1px solid #020',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	DownloadIcon: {
		height: '140px',
		width: '140px'
	},
	submitButton: {
		color: '#ffffff',
		backgroundColor: 'teal',
		textTransform: 'capitalize',
		borderRadius: '8px',
		float: 'left',
		position: 'relative',
		bottom: '50px',
		margin: '60px 0px 20px 0px',
		width: '200px'
	}
}));

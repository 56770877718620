import axiosInstanceForTemplate from './axiosInstanceForTemplate';

export const getTemplateInfo = async (id) => {
	try {
		const res = await axiosInstanceForTemplate.get(`/template/${id}`, {
			data: null
		});

		return res;
	} catch (error) {
		return error;
	}
};

export default { getTemplateInfo };

import axiosInstanceForTemplate from "./axiosInstanceForTemplate";

export const allProductCatalog = async (params) => {
  try {
    const res = await axiosInstanceForTemplate.get(`/product-catalog`);

    return res;
  } catch (error) {
    return error.response.data;
  }
};

import axiosInstanceForTemplate from "./axiosInstanceForTemplate";

export const editProductCatalog = async (id, data) => {
  try {
    const res = await axiosInstanceForTemplate.patch(
      `/product-catalog/${id}`,
      data
    );

    return res;
  } catch (error) {
    return error;
  }
};

export default { editProductCatalog };

import axiosInstanceForTemplate from "./axiosInstanceForTemplate";

export const getProductCatalogById = async (id) => {
  try {
    const res = await axiosInstanceForTemplate.get(`/product-catalog/${id}`, {
      data: null,
    });

    return res;
  } catch (error) {
    return error;
  }
};

export default { getProductCatalogById };

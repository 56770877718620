import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing }) => ({
	container: {
		display: 'flex',
		gap: '8px',
		flexDirection: 'column'
	},
	colorItemContainer: {
		width: '50%',
		display: 'flex',
		alignItems: 'center'
	},
	inputFieldsTitle: {
		color: '#42536b',
		fontSize: '14px',
		fontWeight: '400'
	},
	colorContainer: {
		width: '100%',
		display: 'flex',
		gap: '8px',
		padding: '5px 10px',
		border: '1px solid darkGray'
	},
	colorBox: {
		width: '20px',
		height: '20px'
	},
	pickNewButton: {
		display: 'flex',
		alignItems: 'center',
		textTransform: 'none',
		borderRadius: '25px',
		fontSize: spacing(1.5),
		padding: spacing(1, 2),
		'& .MuiButton-label': {
			gap: '8px'
		}
	}
}));

import React from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';

const ActivityRow = ({ id, activityCode, title }) => {
	const classes = useStyles();
	return (
		<TableRow>
			<TableCell className={classes.tableCell} width='40%'>
				{activityCode}
			</TableCell>
			<TableCell className={classes.tableCell} width='60%'>
				{title}
			</TableCell>
		</TableRow>
	);
};

const useStyles = makeStyles({
	tableCell: {
		maxWidth: '20%',
		minWidth: '15%',
		fontSize: '11px'
	},
	activity: {
		maxWidth: '10%'
	},
	institute: {
		width: '5%'
	},
	tableRow: {
		fontSize: '11px'
	}
});

export default ActivityRow;

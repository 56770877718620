import axiosInstanceForTemplate from "./axiosInstanceForTemplate";

export const updateCategory = async (id, requestBody) => {
  try {
    const res = await axiosInstanceForTemplate.put(`/category/${id}`, {
      categoryName: requestBody.categoryName,
      source: requestBody.source,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Drawer,
  makeStyles,
  Hidden,
  ListItem,
  ListItemText,
  Icon,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import { VideoLabel, ShoppingCart, Book } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import BookIcon from "@material-ui/icons/Book";
import { CollectionsBookmark, ExitToApp } from "@material-ui/icons";
import { Category } from "@material-ui/icons";

import { VideoLabelSharp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import MenuItem from "./MenuItem";
import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle";

const Sidebar = (props) => {
  const classes = useStyles();
  const { open, handleDrawerToggle } = props;
  const { push } = useHistory();
  const { image } = props;

  const [dropDownOpen, setDropDownOpen] = useState("");
  const dropdownToggle = (key) => {
    setDropDownOpen(dropDownOpen === key ? "" : key);
  };

  const page = window.location.href.split("/").slice(-2)[0];

  const isUserIn = window.localStorage.getItem("userIn");
  if (!isUserIn) {
    push("/login");
  }

  useEffect(() => {
    setDropDownOpen(page || "");
  }, [page]);

  const handleLogOut = async () => {
    await window.localStorage.removeItem("userIn");
    push("/login");
  };

  const brand = (
    <div className={classes.logo} style={{ background: "#008080" }}>
      <Link to="/dashboard" className={classNames(classes.logoLink)}>
        <div className={classes.logoImage} style={{ background: "#008080" }}>
          <img
            src="./logo2.png"
            alt="logo"
            className={classes.img}
            style={{ width: "50%" }}
          />
        </div>
      </Link>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css" style={{ background: "#008080" }}>
        <Drawer
          variant="temporary"
          open={open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div
            className={classes.sidebarWrapper}
            style={{ background: "#008080" }}
          >
            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === "manage",
              })}
              onClick={() => dropdownToggle("manage")}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <LowPriorityIcon />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Activity Log
                  {dropDownOpen !== "manage" && (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  )}
                  {dropDownOpen === "manage" && (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div>

            {dropDownOpen === "manage" && (
              <>
                <MenuItem
                  icon={BookIcon}
                  name="Activity Codes"
                  to="/activities"
                />

                <MenuItem
                  icon={CollectionsBookmark}
                  name="Activity Log"
                  to="/activityDetails"
                />
              </>
            )}
            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === "template",
              })}
              onClick={() => dropdownToggle("template")}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <VideoLabel />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Manage Templates
                  {dropDownOpen !== "template" && (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  )}
                  {dropDownOpen === "template" && (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                  {dropDownOpen === "template" && (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div>

            {dropDownOpen === "template" && (
              <>
                <MenuItem icon={Category} name="Category" to="/categories" />

                <MenuItem
                  icon={VideoLabelSharp}
                  name="Templates"
                  to="/templates"
                />
              </>
            )}

            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === "catalogue",
              })}
              onClick={() => dropdownToggle("catalogue")}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <Book />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Product Catalogs
                  {dropDownOpen !== "catalogue" ? (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  ) : (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div>
            {dropDownOpen === "catalogue" && (
              <>
                <MenuItem
                  icon={Category}
                  name="Product catalog"
                  to="/productCatalog"
                />
              </>
            )}

            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === "event",
              })}
              onClick={() => dropdownToggle("event")}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <DescriptionIcon />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Event Details
                  {dropDownOpen !== "event" ? (
                    <KeyboardArrowUp className={classes.dropddownIcon} />
                  ) : (
                    <KeyboardArrowDown className={classes.dropddownIcon} />
                  )}
                </ListItemText>
              </ListItem>
            </div>
            {dropDownOpen === "event" && (
              <MenuItem icon={BookIcon} name="Event Info" to="/event-details" />
            )}
            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === "merchandise",
              })}
              style={{ cursor: "pointer" }}
              onClick={() => {
                push("/merchandise");
                dropdownToggle("merchandise");
              }}
            >
              <ListItem button className={`${classes.itemLink}`}>
                <Icon className={`${classes.itemIcon} ${classes.whiteFont}`}>
                  <ShoppingCart />
                </Icon>
                <ListItemText className={classes.submenu}>
                  Merchandise
                </ListItemText>
              </ListItem>
            </div>
            <div
              className={classNames({
                [classes.item]: true,
                [classes.dropDownActive]: dropDownOpen === "users",
              })}
              onClick={() => dropdownToggle("users")}
            ></div>

            <div className={classes.item}>
              <ListItem
                button
                onClick={() => handleLogOut()}
                className={classes.itemLink}
              >
                <Icon className={classNames(classes.itemIcon)}>
                  <ExitToApp />
                </Icon>
                <ListItemText
                  primary="Log Out"
                  className={classNames(classes.itemText)}
                  disableTypography
                />
              </ListItem>
            </div>
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

const useStyles = makeStyles(styles);

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  // bgColor: PropTypes.oneOf(['purple', 'blue', 'green', 'orange', 'red']),
  logo: PropTypes.string,
  image: PropTypes.string,
  // logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

export default Sidebar;

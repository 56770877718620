import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	useTheme,
	Grid,
	FormControl,
	Select,
	MenuItem,
	Input,
	InputLabel,
	TextField,
	Checkbox,
	Chip
} from '@material-ui/core';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import { activities } from '../../../src/config/activityJson.json';
import { allActivityTracks } from '../../dataService/allActivityTracks';
import { allInstitution } from '../../dataService/allInstitution';
import { allActivities } from '../../dataService/getAllActivityCodes';

import Button from '../../components/CustomButtons/Button';

import moment from 'moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};
let queue;

const Filter = ({ searchTerms, setSearchTerms, activityCodeList }) => {
	const [channel, setChannel] = useState('');
	const [activity, setActivity] = useState('');
	const [ipAddress, setIpAddress] = useState('');
	let date = moment.utc().format('YYYY-MM-DDThh:mm');
	let prevDate = moment.utc().subtract(1, 'days').format('YYYY-MM-DDThh:mm');

	const [email, setEmail] = useState('');
	const [participantId, setParticipantId] = useState('');
	const [userName, setUserName] = useState('');
	const [selectedStartDate, setSelectedStartDate] = React.useState(prevDate);
	const [selectedEndDate, setSelectedEndDate] = React.useState(date);

	const classes = useStyles();
	const theme = useTheme();
	const [activityCode, setActivityCode] = useState([]);
	const [institute, setInstitute] = useState([]);
	const [institution, setInstitution] = useState([]);
	const [page, setPage] = useState(0);

	const [activityTitle, setActivityTitle] = useState([]);

	const handleChange = (event) => {
		setActivityCode(event.target.value);
	};

	const handleChangeInstitute = (event) => {
		setInstitution(event.target.value);
	};

	const getallActivity = async () => {
		const res = await allActivities({
			_entity: 'Activity',
			_select: '*'
		});
		setActivityTitle(res.data);
	};

	const getallInstitution = async () => {
		const res = await allInstitution();

		setInstitute(res.data);
	};

	useEffect(() => {
		getallActivity();
		getallInstitution();
	}, []);
	// useEffect(() => {
	//   const updatedSearchTerms = {
	//     ...searchTerms,
	//   }
	//   updatedSearchTerms['startDate'] = prevDate;
	//   updatedSearchTerms['endDate'] = date;
	//   setSearchTerms(updatedSearchTerms);
	// }, [])
	const HandleReset = () => {
		const resetSearchTerm = {
			_entity: 'ActivityTrack',
			_select: '*',
			startDate: moment(prevDate).utc().format('YYYY-MM-DD hh:mm'),
			endDate: moment(date).utc().format('YYYY-MM-DD hh:mm')
		};

		setSearchTerms(resetSearchTerm);

		setActivityCode([]);
		setEmail('');
		setParticipantId('');
		setUserName('');
		setInstitution([]);
		setSelectedStartDate(prevDate);
		setSelectedEndDate(date);
		setPage(0);
	};

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};
		const selectStartDate = moment(selectedStartDate)
			.utc()
			.format('YYYY-MM-DD hh:mm');
		if (selectStartDate.length > 0) {
			updatedSearchTerms['startDate'] = selectStartDate;
		} else {
			delete updatedSearchTerms['startDate'];
		}
		setSearchTerms(updatedSearchTerms);
	}, [selectedStartDate]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};
		const selectEndDate = moment(selectedEndDate)
			.utc()
			.format('YYYY-MM-DD hh:mm');

		if (selectEndDate.length > 0) {
			updatedSearchTerms['endDate'] = selectEndDate;
		} else {
			delete updatedSearchTerms['endDate'];
		}
		setSearchTerms(updatedSearchTerms);
	}, [selectedEndDate]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};
		if (activityCode.length > 0) {
			updatedSearchTerms['activityCode'] = activityCode.join('-');
		} else {
			delete updatedSearchTerms['activityCode'];
		}
		setSearchTerms(updatedSearchTerms);
	}, [activityCode]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};

		if (!email) {
			delete updatedSearchTerms['email'];
			setSearchTerms(updatedSearchTerms);
		}

		if (email.length > 2) {
			updatedSearchTerms['email'] = email;
			clearTimeout(queue);
			queue = setTimeout(() => {
				setSearchTerms(updatedSearchTerms);
			}, 500);
		}
	}, [email]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};

		if (!participantId) {
			delete updatedSearchTerms['participantId'];
			setSearchTerms(updatedSearchTerms);
		}

		if (participantId.length > 2) {
			updatedSearchTerms['participantId'] = participantId;
			clearTimeout(queue);
			queue = setTimeout(() => {
				setSearchTerms(updatedSearchTerms);
			}, 500);
		}
	}, [participantId]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};

		if (!userName) {
			delete updatedSearchTerms['userName'];
			setSearchTerms(updatedSearchTerms);
		}

		if (userName.length > 2) {
			updatedSearchTerms['userName'] = userName;
			clearTimeout(queue);
			queue = setTimeout(() => {
				setSearchTerms(updatedSearchTerms);
			}, 500);
		}
	}, [userName]);

	useEffect(() => {
		const updatedSearchTerms = {
			...searchTerms
		};
		if (institution.length > 0) {
			updatedSearchTerms['institution'] = institution;
			updatedSearchTerms['_page'] = page;
		} else {
			delete updatedSearchTerms['institution'];
			delete updatedSearchTerms['_page'];
		}
		setSearchTerms(updatedSearchTerms);
	}, [institution, page]);

	return (
		<Card>
			<CardBody>
				<Grid container spacing={2}>
					<Grid item md={4}>
						<FormControl className={classes.fullWidth}>
							<InputLabel id='demo-mutiple-chip-label'>Activity</InputLabel>
							<Select
								labelId='demo-mutiple-chip-label'
								id='demo-mutiple-chip'
								multiple
								value={activityCode}
								onChange={handleChange}
								input={<Input id='select-multiple-chip' />}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected.map((value) => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
								MenuProps={MenuProps}
							>
								{activityTitle.map((data) => (
									<MenuItem key={data.activityCode} value={data.activityCode}>
										<Checkbox
											style={{ color: 'teal' }}
											checked={activityCode.indexOf(data.activityCode) > -1}
										/>
										{data.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item md={4}>
						<FormControl className={classes.fullWidth}>
							<InputLabel id='demo-mutiple-chip-label'>Institution</InputLabel>
							<Select
								labelId='demo-mutiple-chip-label'
								id='demo-mutiple-chip'
								value={institution}
								onChange={handleChangeInstitute}
								input={<Input id='select-multiple-chip' />}
								MenuProps={MenuProps}
							>
								{institute.map((data) => (
									<MenuItem key={data.unnest} value={data.unnest}>
										{data.unnest}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid md={4} item>
						<TextField
							value={email}
							onChange={(ev) => setEmail(ev.target.value)}
							className={classes.fullWidth}
							label='Email'
						/>
					</Grid>
					<Grid md={4} item>
						<TextField
							value={participantId}
							onChange={(ev) => setParticipantId(ev.target.value)}
							className={classes.fullWidth}
							label='Participant ID'
						/>
					</Grid>

					<Grid md={4} item>
						<TextField
							value={userName}
							onChange={(ev) => setUserName(ev.target.value)}
							className={classes.fullWidth}
							label='Username'
						/>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					style={{
						paddingTop: '16px'
					}}
				>
					<Grid md={4} item>
						<form className={classes.container} noValidate>
							<TextField
								id='datetime-local-start'
								label='Start Time'
								type='datetime-local'
								defaultValue={prevDate}
								value={selectedStartDate}
								className={classes.fullWidth}
								onChange={(val) => {
									setSelectedStartDate(val.target.value);
								}}
								InputLabelProps={{
									shrink: true
								}}
								size={'small'}
								variant={'outlined'}
								margin={'dense'}
							/>
						</form>
					</Grid>
					<Grid md={4} item>
						<form className={classes.container} noValidate>
							<TextField
								id='datetime-local-end'
								label='End Time'
								type='datetime-local'
								defaultValue={date}
								value={selectedEndDate}
								className={classes.fullWidth}
								onChange={(val) => {
									setSelectedEndDate(val.target.value);
								}}
								InputLabelProps={{
									shrink: true
								}}
								size={'small'}
								variant={'outlined'}
								margin={'dense'}
							/>
						</form>
					</Grid>
					<Grid
						item
						md={4}
						style={{
							display: 'flex',
							justifyContent: 'flex-end'
						}}
					>
						<Button color='success' onClick={HandleReset}>
							Reset
						</Button>
					</Grid>
				</Grid>
			</CardBody>
		</Card>
	);
};

const useStyles = makeStyles((theme) => ({
	topSpacing: {
		marginTop: '30px'
	},
	filterTitle: {
		margin: '0 0 15px 0'
	},
	marginNone: {
		margin: 0
	},
	fullWidth: {
		width: '100%'
	},
	formGroup: {
		'& label': {
			margin: 0
		}
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: 0,
		height: '23px'
	},
	noLabel: {
		marginTop: theme.spacing(3)
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	textField: {
		marginLeft: theme.spacing(0),
		marginRight: theme.spacing(0),
		width: 250
	},
	customDate: {
		height: '10px'
	}
}));

export default Filter;

import { createStore, compose, applyMiddleware } from "redux";
import logger from "redux-logger";
import ReduxPromise from "redux-promise";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer";

const initialState = {
  signIn: {
    signedIn: false,
  },
  addNewUser: {},
};

const enhancers =
  process.env.NODE_ENV === "development"
    ? composeWithDevTools(applyMiddleware(logger, ReduxPromise))
    : compose(applyMiddleware(logger, ReduxPromise));

const store = createStore(rootReducer, initialState, enhancers);

export default store;

import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { AddAlert } from "@material-ui/icons";
import ProductCatalogFormComponent from "./ProductCatalogFormComponent/ProductCatalogFormComponent";
import { getInstitutionsFromClippingPlatform } from "../../../dataService/getInstitutionsFromClippingPlatform";
import { allProductCatalog } from "../../../dataService/getAllProductCatalog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    width: "100%",
  },
  dropzoneHeader: {
    color: "#42536b",
    fontSize: "14px",
    fontWeight: "400",
  },
}));

export default function AddProductCatalog() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [institutionList, setInstitutionList] = useState([]);
  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: "",
    color: "success",
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#008080",
      },
    },
  });

  const [searchTerms, setSearchTerms] = useState({
    _select: "*",
  });

  const createUserRows = useCallback(async () => {
    setIsLoading(true);
    const res = await allProductCatalog(searchTerms);
    if (res.status === 200) {
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [searchTerms]);

  useEffect(() => {
    createUserRows();
  }, [searchTerms]);

  const getInstitutionsListFromClippingPlatform = async () => {
    const res = await getInstitutionsFromClippingPlatform();
    if (res.rows) {
      setInstitutionList(res.rows);
    }
  };

  useEffect(() => {
    getInstitutionsListFromClippingPlatform();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ProductCatalogFormComponent
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isEditPage={false}
        setSnackbarConfig={setSnackbarConfig}
        snackbarConfig={snackbarConfig}
        institutionList={institutionList}
      />
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="tr"
      />
    </MuiThemeProvider>
  );
}

import axiosInstanceForTemplate from "./axiosInstanceForTemplate";

export const createProductCatalog = async (data) => {
  try {
    const res = await axiosInstanceForTemplate.post(`/product-catalog`, data);
    return res;
  } catch (error) {
    return error;
  }
};

export default { createProductCatalog };

import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { ReactComponent as MediaUploadImg } from '../../assets/img/MediaUpload.svg';
import { ReactComponent as AlertIcon } from '../../assets/img/alert-icon.svg';
import { useStyles } from './DropzoneComponent.styles';

const DropzoneComponent = ({
	register,
	acceptFiles,
	dropzoneName,
	handleChange,
	dropzoneHeader,
	maxFileSize,
	dropzoneSubHeader,
	validator,
	getFilesFromEvent,
	errors,
	helperText
}) => {
	const [error, setError] = useState(null);
	const classes = useStyles();

	const handleDrop = (data) => {
		if (!!data.length) {
			handleChange(data);
		}
	};

	const handleReject = (file) => {
		const errorCode = file[0].errors[0]?.code;
		if (errorCode === 'file-too-large') {
			setError({
				type: 'size',
				message: 'file Size exceed'
			});
		} else if (errorCode === 'custom') {
			setError({
				type: 'custom',
				message: file[0].errors[0].message
			});
		}
	};

	return (
		<>
			<Dropzone
				onDrop={handleDrop}
				ref={register}
				accept={acceptFiles}
				maxFiles={1}
				maxSize={maxFileSize && maxFileSize}
				validator={validator}
				getFilesFromEvent={getFilesFromEvent}
				onDropRejected={handleReject}
			>
				{({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
					return (
						<div
							{...getRootProps({ className: classes.container })}
							style={{
								backgroundColor: isDragAccept ? '#42f36b' : isDragReject && '#FF0000'
							}}
						>
							<input {...getInputProps({ name: dropzoneName })} />
							<div
								className={classes.dropzoneContainer}
								style={{
									padding: !error ? '32px 0px' : '44px 0px 45px 0px'
								}}
							>
								{error ? (
									<>
										<AlertIcon className={classes.dropzoneImage} />
										<Typography className={classes.errorMessage}>
											{error.message}
										</Typography>
									</>
								) : (
									<>
										<MediaUploadImg className={classes.dropzoneImage} />
										<Typography
											className={classes.dropzoneHeader}
											style={{
												color: isDragReject ? '#fff' : 'teal'
											}}
										>
											{dropzoneHeader}
										</Typography>
										<Typography
											className={classes.dropzoneSubHeader}
											style={{
												paddingTop: !dropzoneSubHeader && '24px',
												color: isDragReject ? '#fff' : 'teal'
											}}
										>
											{dropzoneSubHeader}
										</Typography>
									</>
								)}
							</div>
						</div>
					);
				}}
			</Dropzone>
			{errors && <div>{helperText}</div>}
		</>
	);
};

export default DropzoneComponent;

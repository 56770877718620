import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles, FormControl, InputLabel, Input } from '@material-ui/core';
// @material-ui/icons
import { Clear, Check } from '@material-ui/icons';
// core components
import styles from '../../assets/jss/material-dashboard-react/components/customInputStyle';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
	const classes = useStyles();
	const {
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		error,
		success,
		groupText
	} = props;

	const { value, error: inputError, ...restInputProps } = inputProps;

	const labelClasses = classNames({
		[` ${classes.labelRootError}`]: error,
		[` ${classes.labelRootSuccess}`]: success && !error
	});
	const underlineClasses = classNames({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true
	});
	const marginTop = classNames({
		[classes.marginTop]: labelText === undefined
	});
	return (
		<div className={classes.inputWrapper}>
			{groupText && groupText.length && (
				<div className={classes.groupTextWrapper}>
					<span className={classes.groupText}>{groupText}</span>
				</div>
				//    <FormControl className={classes.formControl}>
				//    <InputLabel id="demo-simple-select-label">Age</InputLabel>
				//    <Select
				//      labelId="demo-simple-select-label"
				//      id="demo-simple-select"
				//      value={value}
				//    >
				//      <MenuItem value="PROD_">PROD_</MenuItem>
				//      <MenuItem value="KUDOS">KUDOS</MenuItem>
				//    </Select>
				//  </FormControl>
			)}
			<FormControl
				{...formControlProps}
				className={`${formControlProps.className} ${classes.formControl}`}
			>
				{labelText !== undefined ? (
					<InputLabel
						className={classes.labelRoot + labelClasses}
						htmlFor={id}
						{...labelProps}
					>
						{labelText}
					</InputLabel>
				) : null}
				<Input
					className={classes.input}
					classes={{
						root: marginTop,
						disabled: classes.disabled,
						underline: underlineClasses
					}}
					id={id}
					value={value || ''}
					error={!!inputError}
					{...restInputProps}
				/>
				{/* eslint-disable */}
				{error ? (
					<Clear className={`${classes.feedback} ${classes.labelRootError}`} />
				) : success ? (
					<Check className={`${classes.feedback} ${classes.labelRootSuccess}`} />
				) : null}
			</FormControl>
		</div>
	);
}

CustomInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	error: PropTypes.bool,
	success: PropTypes.bool
};

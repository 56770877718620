import React from "react";
import { TableRow, TableCell, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import Button from "../../components/CustomButtons/Button";
import moment from "moment";
import { commissionTypes } from "./AddEditProductCatalog/ProductCatalogFormComponent/ProductCatalogFormComponent.utils";

const ProductCatalogRow = ({
  id,
  resourceType,
  clientType,
  institutionName,
  price,
  currency,
  cutOffDate,
  taxDeducted,
  taxDeductedValue,
  transactionFee,
  transactionFeeValue,
  partnerRevShare,
  commissionStructureValue,
  commissionStructureValueType,
  commissionStructureValues,
}) => {
  const classes = useStyles();

  const pop = JSON.parse(institutionName);
  return (
    <TableRow>
      <TableCell className={classes.tableCellType}>{resourceType}</TableCell>
      <TableCell className={classes.tableCellType}>{clientType}</TableCell>
      <TableCell className={classes.tableCellType}>
        {pop === null ? "" : pop && pop.map((x) => x.name).join(",")}
      </TableCell>
      <TableCell className={classes.tableCellPrice}>{price}</TableCell>
      <TableCell className={classes.tableCellPrice}>{currency}</TableCell>
      <TableCell className={classes.tableCellDate}>
        {!cutOffDate ? "N/A" : moment(cutOffDate).format("DD-MM-YYYY")}
      </TableCell>
      {/* <TableCell className={classes.tableCellTax}>{taxDeducted}</TableCell> */}
      {/* <TableCell className={classes.tableCellTax}>{taxDeductedValue}</TableCell> */}
      {/* <TableCell className={classes.tableCellTax}>{transactionFee}</TableCell> */}
      {/* <TableCell className={classes.tableCellTax}>
        {transactionFeeValue}
      </TableCell>
      <TableCell className={classes.tableCellCommission}>
        {partnerRevShare}
      </TableCell>

      <TableCell className={classes.tableCellCommission}>
        {commissionStructureValueType}
      </TableCell>
      <TableCell className={classes.tableCellCommission}>
        {commissionStructureValues}
      </TableCell>

      <TableCell className={classes.tableCellCommission}>
        {commissionStructureValue}
      </TableCell> */}

      <TableCell className={classes.tableCell}>
        <Link style={{ textDecoration: "none" }} to={`/productCatalog/${id}`}>
          <Button color="success">
            <EditIcon />
            Edit
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles({
  tableCellType: {
    maxWidth: "15%",
    minWidth: "10%",
    fontSize: "11px",
  },
  tableCell: {
    maxWidth: "15%",
    minWidth: "10%",
    fontSize: "11px",
  },
  tableCellPrice: {
    minWidth: "10%",
    fontSize: "11px",
  },
  tableCellDate: {
    minWidth: "10%",
    fontSize: "11px",
  },
  tableCellTax: {
    minWidth: "10%",
    fontSize: "11px",
  },
  tableCellCommission: {
    minWidth: "10%",
    fontSize: "11px",
  },
  activity: {
    maxWidth: "10%",
  },

  tableRow: {
    fontSize: "11px",
  },
  colorBox: {
    width: "15px",
    height: "15px",
    cursor: "pointer",
  },
});

export default ProductCatalogRow;

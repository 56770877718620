import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { getEventInfo } from "../../dataService/getEventDetails";
import NoContentCard from "../../components/NoContentCard";
import Loading from "../../components/Loading";
import FilterEvents from "./FilterEvent";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { isEmpty } from "lodash";

export default () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [eventDetails, setEventDetails] = useState({});

  const getEventDetails = async () => {
    setIsLoading(true);
    const res = await getEventInfo(searchValue);
    if (res.status === 200) {
      //eventDetails.eventData?.name
      setEventDetails(res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (searchValue.length > 5) {
      getEventDetails();
    }
    {
      setEventDetails({});
    }
  }, [searchValue]);

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <>
      <React.Fragment>
        <h3>Event Info</h3>
        <FilterEvents
          handleSearchValueChange={handleSearchValueChange}
          searchValue={searchValue}
        />
      </React.Fragment>
      {isLoading && (
        <Loading
          className={classes.topSpacing}
          boxHeight="calc(100vh - 120px)"
        />
      )}

      {!isLoading && eventDetails.eventData?.name == null && (
        <Grid container justify="flex-end" className={classes.topSpacing}>
          <Grid item md={12}>
            <NoContentCard message="No event data found" />
          </Grid>
        </Grid>
      )}

      {eventDetails.eventData?.name != null && !isLoading && (
        <Card className={classes.wrapperCard}>
          <Typography className={classes.typographyEvent}>
            {eventDetails.eventData.name}
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(eventDetails.eventData.name);
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </Typography>

          <div
            style={{
              height: "2px",
              backgroundColor: "gray",
              width: "100%",
              margin: "10px 0px",
            }}
          />
          {eventDetails.eventData?.institutionName && (
            <div>
              <Typography className={classes.typographySubTitle}>
                Institution
              </Typography>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
              >
                {/* <Typography className={classes.typographySub}>
              Name : {""}
             
					  </Typography> */}
                <TextField
                  label="Name"
                  font-size="42px"
                  value={eventDetails.eventData?.institutionName}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              eventDetails.eventData?.institutionName
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                />
                {/* <Typography className={classes.typographySub}>
              Id : {""}

					  </Typography> */}
                <TextField
                  label="Id"
                  value={eventDetails.eventData?.institutionId}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              eventDetails.eventData?.institutionId
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                />
              </div>
            </div>
          )}
          {eventDetails.eventData?.accountName && (
            <div>
              <Typography className={classes.typographySubTitle}>
                Account
              </Typography>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
              >
                {/* <Typography className={classes.typographySub}>
              Id : {""}
						 
					  </Typography> */}
                <TextField
                  label="Id"
                  value={eventDetails.eventData?.accountId}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              eventDetails.eventData?.accountId
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                />
                {/* <Typography className={classes.typographySub}>
              Name : {""}
						  
					  </Typography> */}
                <TextField
                  label="Name"
                  value={eventDetails.eventData?.accountName}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              eventDetails.eventData?.accountName
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                />
              </div>
            </div>
          )}

          {eventDetails.user?.id && (
            <div>
              <Typography className={classes.typographySubTitle}>
                User
              </Typography>

              <div
                style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
              >
                {/* <Typography className={classes.typographySub}>Id : {""}</Typography> */}
                <TextField
                  label="Id"
                  value={eventDetails.user?.id}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              eventDetails.user?.id
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                />

                {/* <Typography className={classes.typographySub}>
              First Name : {""}
						  
					  </Typography> */}
                <TextField
                  label="First Name"
                  value={eventDetails.user?.firstName}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(
                              eventDetails.user?.firstName
                            );
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={classes.textField}
                />
              </div>
              <div
                style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
              >
                <div style={{ display: "flex", width: "50%" }}>
                  {/* <Typography className={classes.typographySub}>
                Last Name :{" "}
              </Typography> */}
                  <TextField
                    label="Last Name"
                    value={eventDetails.user?.lastName}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(
                                eventDetails.user?.lastName
                              );
                            }}
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textField}
                  />
                </div>

                <div style={{ display: "flex", width: "50%" }}>
                  {/* <Typography className={classes.typographySub}>
                Email :{" "}
              </Typography> */}
                  <TextField
                    label="Email"
                    value={eventDetails.user?.email}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(
                                eventDetails.user?.email
                              );
                            }}
                          >
                            <FileCopyIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textField}
                  />
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </>
  );
};

const useStyles = makeStyles({
  topSpacing: {
    marginTop: "30px",
  },
  wrapperCard: {
    marginBottom: "10px",
    marginTop: "20px",
    backgroundColor: "#ffffff",
    padding: "20px 30px",
    gap: "10px",
  },
  textField: {
    margin: "0px",
    padding: "0px 0px 0px 5px",
    outline: "0.5px",
    justifyContent: "center",
    width: "-webkit-fill-available",
    "&:hover": { border: "none" },
  },
  typographySub: {
    color: "#42536b",
    variant: "h3",
    fontSize: "20px",
    fontWeight: "normal",
    width: "-webkit-fill-available",
  },
  typographyEvent: {
    color: "#42536b",
    variant: "h3",
    fontSize: "30px",
    fontWeight: "800",
  },
  typographySubTitle: {
    color: "#42536b",
    variant: "h3",
    fontSize: "25px",
    fontWeight: "600",
    marginTop: "25px",
  },
});

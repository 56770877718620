import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Snackbar from '../../../components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons';
import MerchandiseFormComponent from './merchandiseFormComponent/MerchandiseFormComponent';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		height: '100%',
		width: '100%'
	},
	dropzoneHeader: {
		color: '#42536b',
		fontSize: '14px',
		fontWeight: '400'
	}
}));

export default function AddMerchandise() {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);

	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	const theme = createTheme({
		palette: {
			primary: {
				main: '#008080'
			}
		}
	});

	return (
		<MuiThemeProvider theme={theme}>
			<MerchandiseFormComponent
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				isEditPage={false}
				setSnackbarConfig={setSnackbarConfig}
				snackbarConfig={snackbarConfig}
			/>
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</MuiThemeProvider>
	);
}

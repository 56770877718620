import React, { useState, useEffect } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { AddAlert } from "@material-ui/icons";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { getProductCatalogById } from "../../../dataService/getProductCatalogById";
import ProductCatalogFormComponent from "./ProductCatalogFormComponent/ProductCatalogFormComponent";
import { getInstitutionsFromClippingPlatform } from "../../../dataService/getInstitutionsFromClippingPlatform";

const EditProductCatalog = ({
  match: {
    params: { id, data },
  },
}) => {
  const [productCatalogInfo, setProductCatalogInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [institutionList, setInstitutionList] = useState([]);

  const [snackbarConfig, setSnackbarConfig] = useState({
    open: false,
    message: "",
    color: "success",
  });

  const snackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  useEffect(() => {
    setProductCatalogEditForm();
  }, []);

  const setProductCatalogEditForm = async () => {
    setIsLoading(true);
    const res = await getProductCatalogById(id);
    if (res.status === 200) {
      if (res.data.data) {
        setProductCatalogInfo(res.data.data);
      }
    }
    setIsLoading(false);
  };

  const getInstitutionsListFromClippingPlatform = async () => {
    const res = await getInstitutionsFromClippingPlatform();
    if (res.rows) {
      setInstitutionList(res.rows);
    }
  };

  useEffect(() => {
    getInstitutionsListFromClippingPlatform();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ProductCatalogFormComponent
        id={id}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isEditPage={true}
        productCatalogInfo={productCatalogInfo}
        setSnackbarConfig={setSnackbarConfig}
        snackbarConfig={snackbarConfig}
        institutionList={institutionList}
      />
      <Snackbar
        {...snackbarConfig}
        icon={AddAlert}
        close
        closeNotification={snackBarClose}
        place="tr"
      />
    </MuiThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#008080",
    },
  },
});

export default EditProductCatalog;

import axiosInstanceForClippingPlatform from './axiosInstance/axiosInstanceForClippingPlatform';

export const getInstitutionsFromClippingPlatform = async () => {
	try {
		const res = await axiosInstanceForClippingPlatform.get(
			`/admin/institutions?all=true`,
			{
				data: null
			}
		);
		return res.data;
	} catch (error) {
		return error.response.data;
	}
};

import * as yup from "yup";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const handleGetFilesFromEvent = async (event) => {
  const file =
    event.target?.files?.[0] || event.dataTransfer?.items?.[0].getAsFile();
  const promises = [];

  if (file) {
    if (file.type === "video/mp4") {
      const promise = new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);

        video.onloadedmetadata = () => {
          file.width = video.videoWidth;
          file.height = video.videoHeight;
          resolve(file);
        };
      });
      promises.push(await promise);
      return Promise.all(promises);
    }

    const promise = new Promise((resolve) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        if (file) {
          file.width = image.width;
          file.height = image.height;
          resolve(file);
        }
      };
    });
    promises.push(await promise);
  }
  return Promise.all(promises);
};

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export const merchandiseSchema = yup.object().shape({
  name: yup.string().required("You must enter merchandise name"),
  source: yup.string().required("You must select the source"),
  price: yup.string().required("You must enter merchandise price").nullable(),
  image: yup.mixed().required("Image is required").nullable(),
});

import React from 'react';
import {
	TableRow,
	TableCell,
	Tooltip,
	makeStyles,
	useTheme
} from '@material-ui/core';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { teal } from '@material-ui/core/colors';
import { InfoRounded } from '@material-ui/icons';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DescriptionIcon from '@material-ui/icons/Description';
import DnsIcon from '@material-ui/icons/Dns';
import { Person } from '@material-ui/icons';

const ActivityRow = ({
	id,
	activityCode,
	email,
	channel,
	createdAt,
	institution,
	data,
	userName
}) => {
	createdAt = moment(createdAt).toString();

	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState('');
	const classes = useStyles();
	const handleClickOpen = () => {
		setOpen(true);
	};

	return (
		<TableRow className={classes.tableRow}>
			<TableCell className={classes.tableCell}>{createdAt || '-'}</TableCell>
			<TableCell className={classes.tableCell}>{activityCode}</TableCell>
			<TableCell className={classes.tableCell}>{email}</TableCell>
			<TableCell className={classes.tableCell}>{institution}</TableCell>
			<TableCell className={classes.tableCell}>{channel || '-'}</TableCell>
			<TableCell align='center' className={classes.tableCell}>
				<Tooltip
					className={classes.tableCell}
					style={{ fontSize: '5px' }}
					title={
						<List>
							{userName && (
								<ListItem button key={id}>
									<ListItemAvatar>
										<Avatar style={{ backgroundColor: teal[100], color: teal[600] }}>
											<Person />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										style={{ fontSize: '8px' }}
										classes={{ primary: classes.toolTip }}
										primary={userName}
									/>
								</ListItem>
							)}
							{data.description !== '' ? (
								<div>
									<ListItem button key={email} style={{ fontSize: '6px' }}>
										<ListItemAvatar>
											<Avatar style={{ backgroundColor: teal[100], color: teal[600] }}>
												<DescriptionIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											classes={{ primary: classes.toolTip }}
											style={{ fontSize: '10px' }}
											primary={data.description}
										/>
									</ListItem>

									<ListItem button key={id}>
										<ListItemAvatar>
											<Avatar style={{ backgroundColor: teal[100], color: teal[600] }}>
												<DnsIcon />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											style={{ fontSize: '8px' }}
											classes={{ primary: classes.toolTip }}
											primary={data.ipAddress || 'no ip address found'}
										/>
									</ListItem>
								</div>
							) : (
								<ListItem button key={id}>
									<ListItemAvatar>
										<Avatar style={{ backgroundColor: teal[100], color: teal[600] }}>
											<DnsIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										style={{ fontSize: '8px' }}
										classes={{ primary: classes.toolTip }}
										primary={data.ipAddress || 'no ip address found'}
									/>
								</ListItem>
							)}
						</List>
					}
				>
					<Button onClick={handleClickOpen}>
						<InfoRounded style={{ color: 'teal' }} />
					</Button>
				</Tooltip>
			</TableCell>
		</TableRow>
	);
};

const useStyles = makeStyles({
	tableCell: {
		maxWidth: '20%',
		minWidth: '15%',
		fontSize: '11px'
	},
	activity: {
		maxWidth: '10%'
	},
	institute: {
		width: '5%'
	},
	tableRow: {
		fontSize: '11px'
	},
	toolTip: {
		fontSize: '12px'
	}
});

export default ActivityRow;

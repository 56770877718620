import React, { useState, useEffect } from 'react';
import {
	TableRow,
	TableCell,
	makeStyles,
	IconButton,
	Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { Image, VideoLibrary, Description } from '@material-ui/icons';
import { DarkTooltip } from '../../components/Tooltip/TooltipComponent';
import MediaPreviewModal from '../../components/MediaPreviewModal/MediaPreviewModal';
import Button from '../../components/CustomButtons/Button';

const TemplateRow = ({
	id,
	name,
	thumbnail,
	video,
	zipFile,
	categories,
	source,
	categoryId,
	studentThumbnail,
	ceremonyThumbnail
}) => {
	const classes = useStyles();
	const [openMediaPreviewModal, setOpenMediaPreviewModal] = useState();
	const [mediaPreviewTypeName, setMediaPreviewTypeName] = useState({});
	const [categoryName, setCategoryName] = useState('');

	useEffect(() => {
		if (categories.length) {
			const templateCategoryName = categories.filter(
				(category) => category.id === categoryId
			)[0].categoryName;
			setCategoryName(templateCategoryName || '');
		}
		return () => {
			setCategoryName('');
		};
	}, [categories]);

	return (
		<>
			<TableRow>
				<TableCell className={classes.tableCell}>{name}</TableCell>
				<TableCell className={classes.tableCell}>
					<DarkTooltip placement='top' title='Template Thumbnail'>
						<IconButton
							onClick={() => {
								setOpenMediaPreviewModal('Image');
								setMediaPreviewTypeName({
									name: 'Template Thumbnail',
									file: thumbnail
								});
							}}
							style={{ textDecoration: 'none', color: 'teal' }}
						>
							<Image />
						</IconButton>
					</DarkTooltip>
					<DarkTooltip placement='top' title='Recognition Clip Thumbnail'>
						<IconButton
							onClick={() => {
								setOpenMediaPreviewModal('Image');
								setMediaPreviewTypeName({
									name: 'Recognition Clip Thumbnail',
									file: studentThumbnail
								});
							}}
							style={{ textDecoration: 'none', color: 'teal' }}
						>
							<Image />
						</IconButton>
					</DarkTooltip>
					<DarkTooltip placement='top' title='Ceremony Thumbnail'>
						<IconButton
							onClick={() => {
								setOpenMediaPreviewModal('Image');
								setMediaPreviewTypeName({
									name: 'Ceremony Thumbnail',
									file: ceremonyThumbnail
								});
							}}
							style={{ textDecoration: 'none', color: 'teal' }}
						>
							<Image />
						</IconButton>
					</DarkTooltip>
				</TableCell>
				<TableCell className={classes.tableCell}>
					<DarkTooltip placement='top' title='Sample Video'>
						<IconButton
							onClick={() => {
								setOpenMediaPreviewModal('Video');
								setMediaPreviewTypeName({ name: 'Sample Video', file: video });
							}}
							style={{ textDecoration: 'none', color: 'teal' }}
						>
							<VideoLibrary />
						</IconButton>
					</DarkTooltip>
				</TableCell>
				<TableCell className={classes.tableCell}>
					<a
						style={{ textDecoration: 'none', color: 'teal' }}
						title={'Zip File'}
						href={zipFile}
					>
						<Description />
					</a>
				</TableCell>
				<TableCell className={classes.tableCell}>{source}</TableCell>

				<TableCell className={classes.tableCell}>{categoryName}</TableCell>

				<TableCell className={classes.tableCellSource}>
					<Link
						style={{ textDecoration: 'none', color: 'teal' }}
						to={`template/${id}`}
					>
						<Button color='success'>
							<EditIcon />
							Edit
						</Button>
					</Link>
				</TableCell>
			</TableRow>
			{openMediaPreviewModal && (
				<MediaPreviewModal
					open={openMediaPreviewModal}
					handleClose={() => setOpenMediaPreviewModal(false)}
					// image={thumbnail}
					mediaPreviewTypeName={mediaPreviewTypeName}
					zipFile={zipFile}
					templateName={name}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles({
	tableCell: {
		maxWidth: '20%',
		minWidth: '15%',
		fontSize: '11px'
	},
	tableCellSource: {
		minWidth: '15%',
		fontSize: '11px'
	},
	activity: {
		maxWidth: '10%'
	},
	institute: {
		width: '5%'
	},
	tableRow: {
		fontSize: '11px'
	}
});

export default TemplateRow;

import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  ListItemText,
  CircularProgress,
  Card,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { AddAlert } from "@material-ui/icons";
import { editTemplate } from "../../../dataService/editTemplate";
import { addTemplate } from "../../../dataService/addTemplate";
import { ReactComponent as DownloadIcon } from "../../../assets/img/icon-list-download.svg";
import DropzoneComponent from "../../../components/DropzoneComponent/DropzoneComponent";
import { useStyles } from "./TemplateComponent.styles";
import {
  handleFileValidation,
  handleGetFilesFromEvent,
  MenuProps,
  templateSchema,
} from "./TemplateComponent.utils";

const TemplateComponent = ({
  categories = [],
  isLoading,
  templateInfo = {},
  isEditPage,
  setIsLoading,
  id,
  setSnackbarConfig,
}) => {
  const { push } = useHistory();
  const classes = useStyles();

  const defaultValues = {
    name: "",
    categoryId: null,
    video: "",
    thumbnail: "",
    zipFile: "",
    ceremonyThumbnail: "",
    studentThumbnail: "",
    isActive: true,
  };

  const { register, handleSubmit, setValue, control, formState } = useForm({
    shouldUnregister: false,
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(templateSchema),
  });
  const { errors } = formState;

  useEffect(() => {
    if (!_.isEmpty(templateInfo)) {
      setValue("name", templateInfo.name);
      setValue("video", templateInfo.video);
      setValue("categoryId", templateInfo.categoryId);
      setValue("thumbnail", templateInfo.thumbnail);
      setValue("zipFile", templateInfo.zipFile);
      setValue("isActive", templateInfo.isActive);
      setValue("ceremonyThumbnail", templateInfo.ceremonyThumbnail);
      setValue("studentThumbnail", templateInfo.studentThumbnail);
    }
  }, [templateInfo]);

  const handleFormSubmit = async (values) => {
    isEditPage ? handleFormEditSubmit(values) : handleFormAddSubmit(values);
  };

  const handleFormEditSubmit = async (values) => {
    setIsLoading(true);
    if (values.video.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Sample video is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (values.zipFile?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Template zip file is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (values.thumbnail?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Thumbnail is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (values.ceremonyThumbnail?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Ceremony thumbnail is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (values.studentThumbnail?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Student thumbnail is required",
        icon: AddAlert,
        color: "danger",
      });
    } else {
      const obj = {
        name: values.name,
        categoryId: values.categoryId,
        video: _.isArray(values.video) ? values.video[0] : values.video,
        thumbnail: _.isArray(values.thumbnail)
          ? values.thumbnail[0]
          : values.thumbnail,
        zipFile: _.isArray(values.zipFile) ? values.zipFile[0] : values.zipFile,
        ceremonyThumbnail: _.isArray(values.ceremonyThumbnail)
          ? values.ceremonyThumbnail[0]
          : values.ceremonyThumbnail,
        studentThumbnail: _.isArray(values.ceremonyThumbnail)
          ? values.studentThumbnail[0]
          : values.studentThumbnail,
        isActive: values.isActive,
      };
      const res = await editTemplate(id, obj);
      if (res.status === "success") {
        setSnackbarConfig({
          open: true,
          message: "Template edited successfully!",
          icon: AddAlert,
          color: "success",
        });
        setTimeout(() => {
          push("/templates");
        }, 1000);
      } else {
        setSnackbarConfig({
          open: true,
          message: "Error in update",
          icon: AddAlert,
          color: "error",
        });
      }
    }

    // setIsLoading(false);
  };

  const handleFormAddSubmit = async (data) => {
    setIsLoading(true);

    if (data.video.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Sample video is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (data.zipFile?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Template zip file is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (data.thumbnail?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Thumbnail is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (data.ceremonyThumbnail?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Ceremony thumbnail is required",
        icon: AddAlert,
        color: "danger",
      });
    } else if (data.studentThumbnail?.length === 0) {
      setSnackbarConfig({
        open: true,
        message: "Student thumbnail is required",
        icon: AddAlert,
        color: "danger",
      });
    } else {
      const templateData = {
        name: data.name,
        categoryId: data.categoryId,
        video: data.video[0],
        thumbnail: data.thumbnail[0],
        zipFile: data.zipFile[0],
        ceremonyThumbnail: data.ceremonyThumbnail[0],
        studentThumbnail: data.studentThumbnail[0],
        isActive: data.isActive,
      };
      const res = await addTemplate(templateData);
      if (res.status === "success") {
        setSnackbarConfig({
          open: true,
          message: "Template creation successful!",
          icon: AddAlert,
          color: "success",
        });
        setTimeout(() => {
          push("/templates");
        }, 1000);
      } else {
        setSnackbarConfig({
          open: true,
          message: "Template creation unsuccessful!",
          icon: AddAlert,
          color: "error",
        });
      }
    }

    // setIsLoading(false);
  };

  return (
    <div className={classes.container}>
      <Card className={classes.hederContainer}>
        <Typography variant="h4" className={classes.hederText}>
          {isEditPage ? "Edit Template" : "Add New Template"}
        </Typography>
      </Card>

      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} title="Loading...." />
        </div>
      ) : (
        <div className={classes.templatesFormContainer}>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  label="Template Name"
                  id="name"
                  name="name"
                  control={control}
                  variant="outlined"
                  fullWidth
                  render={({ onChange, value }, {}) => {
                    return (
                      <TextField
                        id="name"
                        variant="outlined"
                        label="Template Name"
                        MenuProps={MenuProps}
                        value={value}
                        onChange={onChange}
                        fullWidth
                        disabled={isEditPage}
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="categoryId"
                  id="categoryId"
                  render={({ onChange, value }) => {
                    return (
                      <TextField
                        className={classes.selectionField}
                        select={!_.isEmpty(categories)}
                        id="categoryId"
                        variant="outlined"
                        label="Categories"
                        MenuProps={MenuProps}
                        disabled={!_.isEmpty(categories)}
                        SelectProps={{
                          value: value,
                          renderValue: (selected) => {
                            const selectedCategory =
                              selected &&
                              !_.isEmpty(categories) &&
                              categories.filter(
                                (category) => category.id === selected
                              )[0];
                            return (
                              selectedCategory && selectedCategory.categoryName
                            );
                          },
                          onChange: onChange,
                          disabled: _.isEmpty(categories),
                        }}
                        error={!!errors.categoryId}
                        helperText={errors?.categoryId?.message}
                      >
                        {!_.isEmpty(categories) &&
                          categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              <ListItemText
                                primary={
                                  category.categoryName +
                                  " (" +
                                  category.source +
                                  ")"
                                }
                              />
                            </MenuItem>
                          ))}
                      </TextField>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  id="isActive"
                  name="isActive"
                  rules={{ required: true }}
                  render={({ onChange, value }) => {
                    console.log(value);
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={!isEditPage}
                                disabled={!isEditPage}
                                checked={value}
                                color="primary"
                                size="medium"
                                onChange={(event) =>
                                  onChange(event.target.checked)
                                }
                              />
                            }
                            label={value ? "Active" : "Inactive"}
                          />
                        </FormGroup>
                      </div>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Upload Sample Video
                </Typography>

                <div className={classes.controllerContainer}>
                  <Controller
                    control={control}
                    id="video"
                    name="video"
                    rules={{ required: "This is required." }}
                    render={({ onChange, value }) => {
                      const isMediaString = _.isString(value);
                      const mediaUrl = isMediaString
                        ? value
                        : URL.createObjectURL(value[0]);
                      return !value ? (
                        <DropzoneComponent
                          errors={!!errors.video}
                          helperText={errors?.video?.message}
                          dropzoneName="video"
                          handleChange={onChange}
                          acceptFiles="video/*"
                          register={register}
                          maxFileSize={150000000}
                          dropzoneHeader="Drag and drop video here or click"
                          dropzoneSubHeader="(max 150MB, 16:9 aspect ratio)"
                          validator={handleFileValidation}
                          getFilesFromEvent={handleGetFilesFromEvent}
                          key="video"
                        />
                      ) : (
                        <div className={classes.mediaContainer}>
                          <video
                            key="video"
                            disablePictureInPicture
                            disableRemotePlayback
                            controls
                            playsInline
                            className={classes.media}
                          >
                            <source src={mediaUrl} type="video/mp4" />
                          </video>
                          <Button
                            onClick={() => setValue("video", "")}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Upload Template Zip File
                </Typography>

                <div className={classes.controllerContainer}>
                  <Controller
                    control={control}
                    id="zipFile"
                    name="zipFile"
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      return !value ? (
                        <DropzoneComponent
                          errors={!!errors.zipFile}
                          helperText={errors?.zipFile?.message}
                          dropzoneName="zipFile"
                          handleChange={onChange}
                          acceptFiles=".zip,.rar,.7zip"
                          register={register}
                          maxFileSize={524288000}
                          dropzoneHeader="Drag and drop zip file here or click"
                          dropzoneSubHeader=""
                          key="zipFile"
                        />
                      ) : (
                        <div className={classes.mediaContainer}>
                          <div className={classes.zipFile}>
                            <a
                              style={{
                                textDecoration: "none",
                                color: "teal",
                                marginTop: "10px",
                              }}
                              title={"Download zip File"}
                              href={value}
                            >
                              <DownloadIcon className={classes.DownloadIcon} />
                            </a>
                          </div>
                          <Button
                            onClick={() => setValue("zipFile", "")}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Upload Templates Thumbnail
                </Typography>
                <div className={classes.controllerContainer}>
                  <Controller
                    control={control}
                    id="thumbnail"
                    name="thumbnail"
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      const isMediaString = _.isString(value);
                      const mediaUrl = isMediaString
                        ? value
                        : URL.createObjectURL(value[0]);
                      return !value ? (
                        <DropzoneComponent
                          errors={!!errors.thumbnail}
                          helperText={errors?.thumbnail?.message}
                          dropzoneName="thumbnail"
                          handleChange={onChange}
                          acceptFiles="image/*"
                          register={register}
                          dropzoneHeader="Drag and drop thumbnail here or click"
                          dropzoneSubHeader="(16:9 aspect ratio)"
                          validator={handleFileValidation}
                          getFilesFromEvent={handleGetFilesFromEvent}
                          key="thumbnail"
                        />
                      ) : (
                        <div className={classes.mediaContainer}>
                          <img
                            src={mediaUrl}
                            alt="thumbnail"
                            className={classes.media}
                          />
                          <Button
                            onClick={() => setValue("thumbnail", "")}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Upload Recognition Clip Thumbnail
                </Typography>

                <div className={classes.controllerContainer}>
                  <Controller
                    control={control}
                    id="studentThumbnail"
                    name="studentThumbnail"
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      const isMediaString = _.isString(value);
                      const mediaUrl = isMediaString
                        ? value
                        : URL.createObjectURL(value[0]);
                      return !value ? (
                        <DropzoneComponent
                          errors={!!errors.studentThumbnail}
                          helperText={errors?.studentThumbnail?.message}
                          dropzoneName="studentThumbnail"
                          handleChange={onChange}
                          acceptFiles="image/*"
                          register={register}
                          dropzoneHeader="Drag and drop recognition clip thumbnail here or click"
                          dropzoneSubHeader="(16:9 aspect ratio)"
                          validator={handleFileValidation}
                          getFilesFromEvent={handleGetFilesFromEvent}
                          key="studentThumbnail"
                        />
                      ) : (
                        <div className={classes.mediaContainer}>
                          <img
                            src={mediaUrl}
                            alt="studentThumbnail"
                            className={classes.media}
                          />
                          <Button
                            onClick={() => setValue("studentThumbnail", "")}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.inputFieldsTitle}>
                  Upload Ceremony Thumbnail
                </Typography>
                <div className={classes.controllerContainer}>
                  <Controller
                    control={control}
                    id="ceremonyThumbnail"
                    name="ceremonyThumbnail"
                    rules={{ required: true }}
                    render={({ onChange, value }) => {
                      const isMediaString = _.isString(value);
                      const mediaUrl = isMediaString
                        ? value
                        : URL.createObjectURL(value[0]);
                      return !value ? (
                        <DropzoneComponent
                          errors={!!errors.ceremonyThumbnail}
                          helperText={errors?.ceremonyThumbnail?.message}
                          dropzoneName="ceremonyThumbnail"
                          handleChange={onChange}
                          acceptFiles="image/*"
                          register={register}
                          dropzoneHeader="Drag and drop ceremony thumbnail here or click"
                          dropzoneSubHeader="(16:9 aspect ratio)"
                          validator={handleFileValidation}
                          getFilesFromEvent={handleGetFilesFromEvent}
                          key="ceremonyThumbnail"
                        />
                      ) : (
                        <div className={classes.mediaContainer}>
                          <img
                            src={mediaUrl}
                            alt="ceremonyThumbnail"
                            className={classes.media}
                          />
                          <Button
                            onClick={() => setValue("ceremonyThumbnail", "")}
                            className={classes.removeButton}
                          >
                            Remove
                          </Button>
                        </div>
                      );
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </div>
  );
};

export default TemplateComponent;

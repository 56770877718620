import axiosInstanceForClippingPlatform from "./axiosInstance/axiosInstanceForClippingPlatform";

export const getEventInfo = async (eventId) => {
  try {
    const res = await axiosInstanceForClippingPlatform.get(
      `/admin/support/event/${eventId}`,
      {
        data: null,
      }
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export default { getEventInfo }
